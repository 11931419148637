const GOOGLE_KEY =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_GOOGLE_KEY_PROD
    : process.env.REACT_APP_GOOGLE_KEY_DEV;

export const places = {
  componentRestrictions: {
    country: ["DE"],
    postalCode: []
  }
};

export const map = {
  url: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}`,
  // url: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCf6NDAXlZ5E1PdqJrAqCqhxEOQ7vYhDWI`,
  defaultZoom: 12,
  defaultCenter: {
    lat: 40.748817,
    lng: -73.985428
  },
  defaultOptions: {
    scrollwheel: false,
    zoomControl: true,
    styles: [
      {
        featureType: "water",
        stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: "#0088ff" }]
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [{ hue: "#ff0000" }, { saturation: -100 }, { lightness: 99 }]
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#808080" }, { lightness: 54 }]
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [{ color: "#ece2d9" }]
      },
      {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [{ color: "#ccdca1" }]
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#767676" }]
      },
      {
        featureType: "road",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ffffff" }]
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        featureType: "landscape.natural",
        elementType: "geometry.fill",
        stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
      },
      { featureType: "poi.park", stylers: [{ visibility: "on" }] },
      {
        featureType: "poi.sports_complex",
        stylers: [{ visibility: "on" }]
      },
      { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
      {
        featureType: "poi.business",
        stylers: [{ visibility: "simplified" }]
      }
    ]
  }
};
