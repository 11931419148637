import api from "../config/api";

const NewsAndStatistaApi = {
  get(city) {
    return api.get("news-statistics", {
      params: {
        city
      }
    });
  }
};

export default NewsAndStatistaApi;
