import * as actionTypes from "../actions/actionTypes";
import * as commonReducer from "./common";

const initState = {
  notifications: [],
  selectedNotification: null
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_SET:
      return commonReducer.save(state, action);
    default:
      return state;
  }
};

export default reducer;
