import api from "../config/api";

export const index = (placeId, lat, lng) => {
  return api.get("general-info", {
    params: {
      placeId,
      lat,
      lng
    }
  });
};

export const getGeneralInformation = (lat, lng, radius = 2000) => {
  const data = {
    data: `[out:json][timeout:25];
node(around:${radius},${lat},${lng})["amenity"="hospital"];
out 1;
node(around:${radius},${lat},${lng})["amenity"="police"];
out 1;
node(around:${radius},${lat},${lng})["amenity"="university"];
out 1;
node(around:${radius},${lat},${lng})["amenity"="fuel"];
out 1;
node(around:${radius},${lat},${lng})["amenity"="parking"];
out 1;
node(around:${radius},${lat},${lng})["amenity"="bank"];
out 1;
node(around:${radius},${lat},${lng})["amenity"="cinema"];
out 1;
node(around:${radius},${lat},${lng})["amenity"="parking"];
out 1;
node(around:${radius},${lat},${lng})["leisure"="park"];
out 1;
node(around:${radius},${lat},${lng})["waterway"="canal"];
out 1;
node(around:${radius},${lat},${lng})["shop"="mall"];
out 1;
node(around:${radius},${lat},${lng})["place"="suburb"];
out 1;`
  };

  return fetch("https://overpass-api.de/api/interpreter", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "*/*"
    },
    body: new URLSearchParams(data)
  });
};
