const collapseSwitcherStyle = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "12px"
  },
  icon: {
    fontSize: "2rem"
  }
};

export default collapseSwitcherStyle;
