import React, { Component } from "react";
import { connect } from "react-redux";

import Map from "../../ui/Map/Map";
import SearchApi from "../../api/Search";

import * as actionCreator from "../../store/actions";

class SearchMap extends Component {
  componentDidMount() {
    if (parseInt(this.props.radius) === 0) {
      SearchApi.shape(this.props.address)
        .then(resp => {
          const json = resp.data.data;

          this.props.setMultiple({
            openStreetMapPlaceId: json.place_id
          });

          return json.geojson.coordinates[0];
        })
        .then(data =>
          this.props.setMultiple({
            cityShape: data
          })
        );
    }
  }

  getMarkers = bounds => {
    bounds = {
      lats: [bounds.getNorthEast().lat(), bounds.getSouthWest().lat()],
      lngs: [bounds.getNorthEast().lng(), bounds.getSouthWest().lng()]
    };

    SearchApi.getMarkers(
      bounds,
      this.props.typeIds[0],
      this.props.typeIds[1],
      this.props.center,
      this.props.radius,
      this.props.address
    ).then(resp => {
      const markers = resp.data.data;
      this.props.setMultiple({
        markers: markers
      });
    });
  };

  onInfoWindowClick = item => {
    const { markers } = this.props;
    const selectedMarker = markers.filter(
      m => m.product_id === item.product_id
    );
    this.props.setSingle("selectedMarker", selectedMarker);
    this.props.toggleModals({
      isModalOpened: true
    });
  };

  render() {
    return (
      <Map
        markers={this.props.markers}
        center={this.props.center}
        radius={this.props.radius}
        shape={this.props.cityShape}
        typeIds={this.props.typeIds}
        onIdle={this.getMarkers}
        onWindowClick={this.onInfoWindowClick}
        isInfoWindow={true}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    markers: state.results.markers,
    cityShape: state.results.cityShape,
    typeIds: state.results.typeIds,
    center: {
      lat: state.search.lat,
      lng: state.search.lng
    },
    address: state.search.address,
    radius: state.search.radius
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMultiple: value => dispatch(actionCreator.setMultiple(value)),
    setSingle: (property, value) =>
      dispatch(actionCreator.set(property, value)),
    toggleModals: value => dispatch(actionCreator.toggleModals(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchMap);
