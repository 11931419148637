import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { geocodeByAddress } from "react-places-autocomplete";

import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Table from "../Table/Table";
import CardFooter from "../Card/CardFooter";
import Card from "../Card/Card";
import TablePaginationActions from "../PaginatorActions/PaginatorActions";

import { TablePagination } from "@material-ui/core";
import * as actionCreator from "../../store/actions";

const GrowthRateTable = props => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const {
    title,
    toggleLoading,
    set,
    value,
    color,
    items,
    isBtnClicked,
    changeSearchParam,
    varianceSearchFormParams,
    rentGrowthCount,
    saleGrowthCount
  } = props;

  const { category } = varianceSearchFormParams;
  const typeName = category === "Alle" ? null : category;

  const { tableHead } = props.table;
  const { sendRequest } = props.methods;

  const tableData = [];

  const requestHandler = useCallback(
    data => {
      const state = {};
      state[value] = data.data;

      set(state);
      setCount(data.total);
      toggleLoading(value, false);
    },
    [set, toggleLoading, value]
  );

  const onTableUpdate = useCallback(
    (page, rowsPerPage, title, category, params) => {
      toggleLoading(value, true);

      sendRequest(page, rowsPerPage, title, category, params)
        .then(resp => resp.data.data)
        .then(data => requestHandler(data));
    },
    [requestHandler, sendRequest, toggleLoading, value]
  );

  useEffect(() => {
    onTableUpdate(page, rowsPerPage, title, typeName, varianceSearchFormParams);
    // eslint-disable-next-line
  }, [onTableUpdate, page, rowsPerPage]);

  useEffect(() => {
    if (isBtnClicked) {
      if (value === "saleGrowth") {
        setCount(saleGrowthCount);
      }
      if (value === "rentGrowth") {
        setCount(rentGrowthCount);
      }
    }
  }, [
    count,
    rowsPerPage,
    rentGrowthCount,
    saleGrowthCount,
    value,
    isBtnClicked
  ]);

  const onChangePageHandler = page => {
    setPage(page);
  };

  const onChangeRowPerPagaHandler = rPerPage => {
    setPage(0);
    setRowsPerPage(rPerPage);
  };

  items.map((item, index) => {
    const id = { label: page * rowsPerPage + index + 1 };
    const name = { label: item.product_zip };
    const rate = { label: item.growth_rate.toFixed(2) + " %" };

    if (tableData[index] === undefined) {
      tableData[index] = [];
    }

    return tableData[index].push(id, name, rate);
  });

  const onRequest = zipcode => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    props.setSingle("totalResults", "");
    props.setMultiple({
      graphicsData: {
        sale: [],
        rent: [],
        saleBelow: [],
        rentBelow: [],
        saleAbove: [],
        rentAbove: []
      }
    });

    geocodeByAddress(`${zipcode} + Germany`).then(data => {
      props.history.push(`/admin/search`);

      url.searchParams.set("adderss", data[0].formatted_address);
      url.searchParams.set("zipcode", zipcode);
      url.searchParams.set("distance", 0);
      url.searchParams.set("type_name", category);

      changeSearchParam("address", data[0].formatted_address);
      changeSearchParam("zipcode", zipcode);
      changeSearchParam("radius", 0);
      changeSearchParam("category", category);

      const newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?${url.search.slice(1)}`;

      window.history.pushState({ path: newUrl }, "", newUrl);
    });
  };

  return (
    <Card>
      <CardHeader color={color}>{title}</CardHeader>
      <CardBody>
        <Table
          tableHeaderColor={color}
          tableHead={tableHead}
          tableData={tableData}
          isClickable={true}
          onCellClick={onRequest}
          clickableKey={1}
        />
      </CardBody>
      <CardFooter>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={3}
          count={count}
          onChangePage={(event, newPage) => onChangePageHandler(newPage)}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={event =>
            onChangeRowPerPagaHandler(parseInt(event.target.value))
          }
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false
          }}
          ActionsComponent={TablePaginationActions}
        />
      </CardFooter>
    </Card>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    set: value => dispatch(actionCreator.save(value)),
    toggleLoading: (property, value) =>
      dispatch(actionCreator.toggleLoadding(property, value)),
    changeSearchParam: (property, value) =>
      dispatch(actionCreator.changeSearchParam(property, value)),
    setMultiple: value => dispatch(actionCreator.setMultiple(value)),
    setSingle: (property, value) => dispatch(actionCreator.set(property, value))
  };
};

const mapStateToProps = state => {
  const { variance } = state;

  return {
    varianceSearchFormParams: {
      growthRate: variance.growthRate,
      radius: variance.radius,
      category: variance.category,
      sizeFrom: variance.sizeFrom,
      sizeTo: variance.sizeTo,
      priceFrom: variance.priceFrom,
      priceTo: variance.priceTo,
      yearFrom: variance.yearFrom,
      yearTo: variance.yearTo,
      rentalPercent: variance.rentalPercent,
      month: "all",
      site: "all",
      categoryValue: "all"
    },
    rentGrowthCount: variance.counts.rentGrowthCount,
    saleGrowthCount: variance.counts.saleGrowthCount,
    isBtnClicked: variance.isBtnClicked
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GrowthRateTable));
