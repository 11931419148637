import React, { Component } from "react";
import { connect } from "react-redux";

import Aux from "../../hoc/AuxReact";

import { Typography } from "@material-ui/core";

import ProductApi from "../../api/Product";
import * as OpenStreetMapAPI from "../../api/OpenStreetMap";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Table from "../../components/Table/Table";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Slider from "../../components/Slider/Slider";
import Map from "../../ui/Map/Map";
import ProductService from "../../services/ProductService";
import * as actionCreator from "../../store/actions";
import CardHeader from "../../components/Card/CardHeader";
import osmDataTypes from "../../assets/osmDataTypes.json";
import NearByInfoService from "../../services/NearByInfoService";
import Loader from "../../ui/Loader/Loader";

class SingleProduct extends Component {
  constructor(props) {
    super(props);
    this.propertyId = props.match.params.propertyId;
    this.state = {
      location: "",
      propertyId: this.propertyId,
      price: "",
      covered_area: "",
      zip_average_covered_percentage: "",
      type: "",
      rooms: "",
      floors: "",
      bedrooms: "",
      garage: "",
      year_constructed: "",
      heating: "",
      address: "",
      zip: "",
      mainImage: "",
      images: [],
      vacant_from: "",
      coords: {
        lat: "",
        lng: ""
      },
      info: []
    };
  }

  componentDidMount() {
    ProductApi.show(this.propertyId)
      .then(resp => resp.data.data)
      .then(data => {
        this.setState({
          ...data
        });

        OpenStreetMapAPI.getGeneralInformation(data.coords.lat, data.coords.lng)
          .then(resp => resp.json())
          .then(data =>
            this.setState({
              ...this.state,
              info: data
            })
          );
      });
  }

  render() {
    const tableData = ProductService.propertyToTableView(
      { ...this.state },
      this.propertyId
    );

    const markers = [];

    if (this.state.coords.lat !== "" && this.state.coords.lng !== "") {
      markers.push({
        product_latitude: this.state.coords.lat,
        product_longitude: this.state.coords.lng
      });
    }

    const content = [];
    if (this.state.info.elements !== undefined) {
      this.state.info.elements.forEach(element => {
        const distance = NearByInfoService.getDistanceFromLatLonInKm(
          this.state.coords.lat,
          this.state.coords.lng,
          element.lat,
          element.lon
        );

        let name = "";
        let icon = "";
        let amenity = "";

        if (osmDataTypes[element.tags.amenity]) {
          amenity = osmDataTypes[element.tags.amenity];
          name = amenity.name;
          icon = amenity.icon;
        } else {
          const tags = { ...element.tags };
          Object.values(tags).map(tag => {
            if (osmDataTypes[tag]) {
              amenity = osmDataTypes[tag];

              name = amenity.name || amenity;
              icon = amenity.icon || "";
            }

            return true;
          });
        }

        content.push([
          {
            label: name.toUpperCase() || ""
          },
          {
            label: element.tags.name || ""
          },
          {
            label: `~ ${distance}, km`
          }
        ]);

        if (amenity.icon)
          markers.push({
            product_latitude: element.lat,
            product_longitude: element.lon,
            product_name: element.tags.name,
            icon: icon
          });
      });
    }

    return (
      <Aux>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={8}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Typography variant={"h5"} gutterBottom>
                  {this.state.location}
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                  <CardBody>
                    <Table
                      tableHeaderColor="info"
                      tableHead={[]}
                      tableData={tableData}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Typography variant={"h5"} gutterBottom>
                  Map
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                {this.state.coords.lat !== "" &&
                this.state.coords.lng !== "" ? (
                  <Map
                    center={this.state.coords}
                    markers={markers}
                    zoom={16}
                    isInfoWindow={false}
                  />
                ) : (
                  ""
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                {this.state.images && this.state.images.length > 0 && (
                  <Slider images={this.state.images} title={"Gallery"} />
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={8}
            style={{ position: "relative" }}
          >
            {content.length <= 0 ? <Loader /> : ""}
            {content.length > 0 ? (
              <Card>
                <CardHeader color="primary">
                  <h3>Allgemeine Information</h3>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHead={["Amenity", "Name", "Distance"]}
                    tableData={content}
                  />
                </CardBody>
              </Card>
            ) : (
              ""
            )}
          </GridItem>
        </GridContainer>
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMultiple: value => dispatch(actionCreator.setMultiple(value))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SingleProduct);
