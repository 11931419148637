import React from "react";
import { connect } from "react-redux";

import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";

import Statistic from "../../Search/Statistic";
import ChartList from "../../Chart/ChartList";
// import NearByInfoTable from "../../Search/NearByInfoTable";

import Loader from "../../../ui/Loader/Loader.js";

const Calculations = props => {
  const { loaders, results, searchFormParams } = props;

  return (
    <GridContainer>
      <GridItem
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ position: "relative" }}
      >
        {loaders.statistic ? <Loader /> : ""}
        {results.totalResults !== "" ? (
          <Statistic
            place={searchFormParams.address}
            totalResults={results.totalResults}
            statisticData={{
              sale: results.sale,
              saleAbove: results.saleAbove,
              saleBelow: results.saleBelow,
              rent: results.rent,
              rentAbove: results.rentAbove,
              rentBelow: results.rentBelow,
              separateValue: searchFormParams.separateValue,
              categoty: searchFormParams.category
            }}
          />
        ) : (
          ""
        )}
      </GridItem>

      <GridItem
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ position: "relative" }}
      >
        {loaders.chartList ? <Loader /> : ""}
        {results.totalResults !== "" ? (
          <ChartList
            city={results.city}
            lat={searchFormParams.lat}
            lng={searchFormParams.lng}
            radius={searchFormParams.radius}
          />
        ) : (
          ""
        )}
      </GridItem>

      {/*{parseInt(searchFormParams.radius) === 0 && results.placeId !== "" ? (*/}
      {/*  <GridItem*/}
      {/*    xs={12}*/}
      {/*    sm={12}*/}
      {/*    md={12}*/}
      {/*    lg={12}*/}
      {/*    style={{ position: "relative" }}*/}
      {/*  >*/}
      {/*    {loaders.nearByInfo ? <Loader /> : ""}*/}
      {/*    {results.totalResults !== "" ? <NearByInfoTable /> : ""}*/}
      {/*  </GridItem>*/}
      {/*) : (*/}
      {/*  ""*/}
      {/*)}*/}
    </GridContainer>
  );
};

const mapStateToProps = state => {
  const { search, results, ui } = state;

  return {
    searchFormParams: {
      lat: search.lat,
      lng: search.lng,
      zipcode: search.zipcode,
      address: search.address,
      radius: search.radius,
      separateValue: search.separateValue,
      category: search.category
    },
    results: {
      city: results.city,
      state: results.state,
      totalResults: results.totalResults,
      sale: results.separatedData.sale,
      saleBelow: results.separatedData.saleBelow,
      saleAbove: results.separatedData.saleAbove,
      rent: results.separatedData.rent,
      rentBelow: results.separatedData.rentBelow,
      rentAbove: results.separatedData.rentAbove
    },
    loaders: {
      statistic: ui.statistic,
      nearByInfo: ui.nearByInfo,
      chartList: ui.chartList
    }
  };
};

export default connect(
  mapStateToProps,
  null
)(Calculations);
