import * as dateFns from "date-fns";

const ProductService = {
  splitDataByType(data) {
    const sale = [];
    const rent = [];

    data.map(item => {
      if (item.type_property === "Mieten") rent.push(item);
      if (item.type_property === "Kaufen") sale.push(item);

      return true;
    });

    return {
      sale,
      rent
    };
  },
  sortData(data, category = "") {
    let monthData = [];
    const result = {};

    data.map(item => {
      if (category && category !== "Alle") {
        if (item.type_name === category) {
          monthData.push({
            month: item.time_month,
            price: item.avg_price
          });
        }
      } else {
        monthData.push({
          month: item.time_month,
          price: item.avg_price
        });
      }
      return true;
    });

    monthData = this.sortMonths(monthData);

    monthData = monthData.map(i => (result[i.month] = i.price));

    return result;
  },
  getAvgOnlineTime(data) {
    const result = [];

    let monthData = this.sortMonths(
      data.map(item => ({
        month: item.time_month,
        avgOnlineTime: item.avg_online_time
      }))
    );
    monthData.forEach(i => (result[i.month] = i.avgOnlineTime));

    return this.filterByMonths(result);
  },
  filterByMonths(obj) {
    let filteredMonths = {};
    Object.keys(obj).forEach(k => {
      const formattedMonth = k.split("-");
      let date = new Date(formattedMonth[1], this.months[formattedMonth[0]]);
      if (+date >= this.unix_timestamp) {
        filteredMonths[k] = obj[k];
      }
    });
    return filteredMonths;
  },
  sortMonths(months) {
    return months.sort((a, b) => {
      const format = "MMM-yyyy";
      const keyA = dateFns.parse(a.month, format, new Date()),
        keyB = dateFns.parse(b.month, format, new Date());
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  },
  generateChartData(data, dataAbove, dataBelow, category) {
    const dataMonths = Object.keys(data);
    const dataPrices = Object.values(data);

    const saleAbove = ProductService.sortData(dataAbove, category);
    const dataAboveMonths = Object.keys(saleAbove);
    const dataAbovePrices = Object.values(saleAbove);

    const saleBelow = ProductService.sortData(dataBelow, category);
    const dataBelowMonths = Object.keys(saleBelow);
    const dataBelowPrices = Object.values(saleBelow);

    return {
      dataMonths,
      dataPrices,
      dataAboveMonths,
      dataAbovePrices,
      dataBelowMonths,
      dataBelowPrices
    };
  },
  calculateGrowthData(data) {
    const keys = Object.keys(data);
    const values = Object.values(data);
    const result = {};

    result[keys[0]] = 0;

    for (let i = 0; i < values.length; i++) {
      if (keys[i + 1] !== undefined)
        result[keys[i + 1]] = (
          ((values[i + 1] - values[i]) / values[i]) *
          100
        ).toFixed(2);
    }

    return result;
  },
  unix_timestamp: 1583712000000,
  months: {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
  },
  propertyToTableView(data, propertyId) {
    return [
      [
        {
          label: "Name"
        },
        {
          label: data.name
        }
      ],
      [
        {
          label: "Inserat ID"
        },
        {
          label: propertyId
        }
      ],
      [
        {
          label: "FlÃ¤che"
        },
        {
          label: data.covered_area
        }
      ],
      [
        {
          label: "Ort"
        },
        {
          label: data.location
        }
      ],
      [
        {
          label: "Preis"
        },
        {
          label: data.price
        }
      ],
      [
        {
          label: "Preisvariation"
        },
        {
          label: data.zip_average_covered_percentage
        }
      ],
      [
        {
          label: "Typ"
        },
        {
          label: data.type
        }
      ],
      [
        {
          label: "Zimmer"
        },
        {
          label: data.rooms
        }
      ],
      [
        {
          label: "Etagenanzahl"
        },
        {
          label: data.floors
        }
      ],
      [
        {
          label: "Bezugsfrei ab"
        },
        {
          label: data.vacant_from
        }
      ],
      [
        {
          label: "Schlafzimmer"
        },
        {
          label: data.bedrooms
        }
      ],
      [
        {
          label: "Garage / Stellplatz"
        },
        {
          label: data.garage
        }
      ],
      [
        {
          label: "Baujahr"
        },
        {
          label: data.year_constructed
        }
      ],
      [
        {
          label: "Heizung"
        },
        {
          label: data.heating
        }
      ],
      [
        {
          label: "Adresse"
        },
        {
          label: data.address
        }
      ],
      [
        {
          label: "Postleitzahl"
        },
        {
          label: data.zip
        }
      ],
      [
        {
          label: "GPS Koordinaten"
        },
        {
          label: data.coords.lat + " " + data.coords.lng
        }
      ]
    ];
  }
};

export default ProductService;