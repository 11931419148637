import React from "react";
import classNames from "classnames";

import styles from "../../assets/jss/material-dashboard-react/ui/backdrop";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const Backdrop = props => {
  const classes = useStyles();
  const backdropClasses = classNames({
    [classes.root]: true,
    [classes.rootFull]: props.isSidebarToggled,
    [classes.rootShort]: !props.isSidebarToggled
  });

  return (
    <div className={backdropClasses}>
      {props.children}
    </div>
  );
};

export default Backdrop;
