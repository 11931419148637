import api from "../config/api";

const index = () => {
  return api.get("alerts");
};

const store = (
  zipcode,
  type,
  typeName,
  minPrice,
  maxPrice,
  minArea,
  maxArea,
  minRooms,
  maxRooms,
  variation,
  mietrendite
) => {
  return api.post("alerts/store", {
    zipcode,
    type,
    typeName,
    minPrice,
    maxPrice,
    minArea,
    maxArea,
    minRooms,
    maxRooms,
    variation,
    mietrendite
  });
};

const update = (
  id,
  zipcode,
  type,
  typeName,
  minPrice,
  maxPrice,
  minArea,
  maxArea,
  minRooms,
  maxRooms,
  variation,
  mietrendite
) =>
  api.put(`alerts/${id}`, {
    zipcode,
    type,
    typeName,
    minPrice,
    maxPrice,
    minArea,
    maxArea,
    minRooms,
    maxRooms,
    variation,
    mietrendite
  });

const show = id => {
  return api.get(`alerts/${id}`);
};

const destroy = id => {
  return api.delete(`alerts/${id}`);
};

export { index, store, show, update, destroy };
