import React, { Component } from "react";
import create from "react-form-validation-hoc";

import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import Alert from "../../components/Alert/Alert";

import { required, emailValidation } from "../../config/validator-rules";

class Auth extends Component {
  inputChangeHandler(event, property) {
    const value = event.target.value;

    this.props.onChange(property, value);
  }

  submitHandler(event) {
    event.preventDefault();

    const { errors } = this.props.validator;
    let isFormValid = false;
    isFormValid = !!errors.email || !!errors.password ? false : true;

    if (isFormValid) {
      this.props.onAuth(this.props.email, this.props.password);
    }
  }

  render() {
    const { FieldDecorator, errors } = this.props.validator;
    const { email, password } = this.props;
    let isFormValid = false;
    isFormValid =
      !!errors.email || !!errors.password || (email === "" || password === "")
        ? false
        : true;

    return (
      <div
        style={{
          height: "100vh",
          position: "relative",
          overflowX: "hidden"
        }}
      >
        <GridContainer
          alignContent={"center"}
          justify={"center"}
          direction={"column"}
          style={{
            position: "absolute",
            top: "50%",
            right: 0,
            left: 0,
            transform: "translateY(-50%)"
          }}
        >
          <GridItem xs={12} sm={12} md={6}>
            <h1 style={{ textAlign: "center" }}>Welcome to FCRImmo</h1>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            {this.props.responseError && (
              <Alert message={this.props.responseError} type={"error"} />
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <form onSubmit={this.submitHandler.bind(this)}>
              <GridItem xs={12} sm={12} md={12}>
                <FieldDecorator
                  name={"email"}
                  value={this.props.email}
                  rules={[required("email"), emailValidation("email")]}
                />
                <CustomInput
                  id="email"
                  inputProps={{
                    type: "email",
                    placeholder: "E-mail",
                    value: this.props.email,
                    onChange: event => this.inputChangeHandler(event, "email")
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={this.props.isWrongCredentials}
                  message={{
                    error: errors.email || this.props.validationError
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FieldDecorator
                  name={"password"}
                  value={this.props.password}
                  rules={[required("password")]}
                />
                <CustomInput
                  id="password"
                  inputProps={{
                    type: "password",
                    placeholder: "Password",
                    value: this.props.password,
                    onChange: event =>
                      this.inputChangeHandler(event, "password")
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={this.props.isWrongCredentials}
                  message={{ error: errors.password }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button type="submit" color="primary" disabled={!isFormValid}>
                  Sign in
                </Button>
              </GridItem>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = props => {
  return {
    email: props.auth.email,
    password: props.auth.password,
    token: props.auth.token,
    isWrongCredentials: props.auth.isWrongCredentials,
    errors: props.auth.validationError,
    responseError: props.auth.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
    onChange: (property, value) =>
      dispatch(actions.changeProperty(property, value))
  };
};

const AuthWithValidation = create()(Auth);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthWithValidation);
