import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Table from "../Table/Table.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";

import styles from "../../assets/jss/material-dashboard-react/components/dashboard/customTable";

const useStyles = makeStyles(styles);

const CustomTable = props => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Simple Table</h4>
        <p className={classes.cardCategoryWhite}>Gesamt: 0</p>
      </CardHeader>
      <CardBody>
        <Table
          tableHeaderColor="primary"
          tableHead={[
            "#",
            "Datum/ID",
            "Verkauf/Miete",
            "Typ",
            "Fläche",
            "Ort",
            "Preis",
            "m² Preis"
          ]}
          tableData={[]}
        />
      </CardBody>
    </Card>
  );
};

export default CustomTable;
