import * as actionTypes from "./actionTypes";

export const setFeaturesSearchParam = (property, value) => {
  return {
    type: actionTypes.SET_FEATURES_SEARCH_PARAM,
    payload: {
      property,
      value
    }
  };
};

export const setFeaturesSearchNestedParam = (property, value, index) => {
  return {
    type: actionTypes.CHANGE_FEATURES_SEARCH_PARAM,
    payload: {
      property,
      value,
      index
    }
  };
};

export const setFeaturesSearchResults = (property, value, index) => {
  return {
    type: actionTypes.SET_FEATURES_SEARCH_RESULTS,
    payload: {
      property,
      value,
      index
    }
  };
};
