import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Backdrop from "../../ui/Backdrop/Backdrop";
import GridItem from "../../components/Grid/GridItem";
import Table from "../../components/Table/Table";
import SelectedAddressService from "../../services/SelectedAddressService";
import styles from "../../assets/jss/material-dashboard-react/ui/Map/selectedAddressModal";

const useStyles = makeStyles(styles);

const SelectedAddressModal = props => {
  const classes = useStyles();
  const { onModalClose, marker, isSidebarToggled } = props;

  const data = SelectedAddressService.getRowData(marker);

  return (
    <Backdrop isSidebarToggled={isSidebarToggled}>
      <GridItem xs={12} sm={12} md={10} lg={10} className={classes.modal}>
        <Table
          tableData={data}
          tableHead={SelectedAddressService.tableHeadItems}
        />
        <span className={classes.closeIcon} onClick={onModalClose}>
          X
        </span>
      </GridItem>
    </Backdrop>
  );
};

export default SelectedAddressModal;
