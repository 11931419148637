import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Table from "../Table/Table";
import Link from "./Link";

import * as NotificationsAPI from "../../api/Notification";
import * as actionCreator from "../../store/actions";

const NotificationTable = props => {
  const { set, notifications, toggleLoading } = props;
  const [switcher, setSwitcher] = useState(false);

  const editHandler = (event, id) => {
    toggleLoading({
      loading: true
    });
    NotificationsAPI.show(id)
      .then(resp => resp.data.data)
      .then(data => {
        set({
          selectedNotification: data
        });
        toggleLoading({
          loading: false,
          openModal: true
        });
      });
  };

  const deleteHandler = (event, id) => {
    const confirm = window.confirm(
      "Are you sure, you want to delete the alert?"
    );
    if (confirm) {
      toggleLoading({
        loading: true
      });
      NotificationsAPI.destroy(id).then(() => {
        setSwitcher(!switcher);
        toggleLoading({
          loading: false
        });
      });
    }
  };

  const mapData = data =>
    data.map(item => [
      {
        label: item.alert_zip
      },
      {
        label: item.alert_kind
      },
      {
        label: item.type ? item.type.type_name : "No set category"
      },
      {
        label: `${item.alert_min_price} - ${item.alert_max_price} €`
      },
      {
        label: `${item.alert_min_area} - ${item.alert_max_area} m²`
      },
      {
        label: `${item.alert_min_rooms} - ${item.alert_max_rooms} Rooms`
      },
      {
        label: `${item.alert_max_percentage} %`
      },
      {
        label: " ",
        components: [
          <Link
            key={"option-1"}
            title={"Delete"}
            color={"danger"}
            onClick={event => deleteHandler(event, item.alert_id)}
          />,
          <Link
            key={"option-2"}
            title={"Edit"}
            color={"info"}
            onClick={event => editHandler(event, item.alert_id)}
          />
        ]
      }
    ]);

  useEffect(() => {
    NotificationsAPI.index()
      .then(resp => resp.data.data)
      .then(data => {
        return set({
          notifications: mapData(data)
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switcher]);

  return (
    <Card>
      <CardHeader color={"primary"}>Alerts list</CardHeader>
      <CardBody>
        <Table
          tableHead={[
            "State",
            "Status",
            "Type",
            "Price",
            "Covered area",
            "Room",
            "Price variation",
            "Options"
          ]}
          tableData={notifications}
        />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    notifications: state.notification.notifications,
    loading: state.ui.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    set: value => dispatch(actionCreator.setNotification(value)),
    toggleLoading: value => dispatch(actionCreator.toggleMultiple(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationTable);
