import * as actionTypes from "./actionTypes";

export const setNotification = value => {
  return {
    type: actionTypes.NOTIFICATION_SET,
    payload: {
      value
    }
  };
};
