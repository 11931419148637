import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

// core components
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import styles from "../../assets/jss/material-dashboard-react/components/feature-search/features";
import FeatureSearchService from "../../services/FeatureSearchService";

const useStyles = makeStyles(styles);

const FeaturesCard = props => {
  const { color, checkedItems, results, featuresSearchParams } = props;
  const { checkNegativeValues } = FeatureSearchService;

  const classes = useStyles();

  let centrality = [];
  let social_status = [];
  let young_population = [];
  let urban = [];

  results.forEach((res, i) => {
    if (res.socialStatuses.centrality !== undefined) {
      centrality.push(res.socialStatuses.centrality);
    }
    if (res.socialStatuses.social_status !== undefined) {
      social_status.push(res.socialStatuses.social_status);
    }
    if (res.socialStatuses.young_population !== undefined) {
      young_population.push(res.socialStatuses.young_population);
    }
    if (res.socialStatuses.urban !== undefined) {
      urban.push(res.socialStatuses.urban);
    }
  });

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={8} lg={8}>
        <div className={classes.featuresWrapper}>
          {centrality.length > 0 &&
            checkedItems[0].isChecked &&
            centrality.map((item, i) => {
              return (
                <GridItem xs={12} sm={6} md={6} key={i}>
                  <Card>
                    <CardHeader color={color} stats>
                      Centrality - {featuresSearchParams[i].objectName}
                    </CardHeader>
                    <React.Fragment>
                      <CardBody>
                        <h4 className={classes.cardTitle}>PLZ</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.plz)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.plzColorPrimary,
                            barColorPrimary: classes.plzBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.plz)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Stadt</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.city)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.cityColorPrimary,
                            barColorPrimary: classes.cityBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.city)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Deutschland</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.country)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.countryColorPrimary,
                            barColorPrimary: classes.countryBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.country)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                    </React.Fragment>
                  </Card>
                </GridItem>
              );
            })}
          {social_status.length > 0 &&
            checkedItems[1].isChecked &&
            social_status.map((item, i) => {
              return (
                <GridItem xs={12} sm={6} md={6} key={i}>
                  <Card>
                    <CardHeader color={color} stats>
                      Social Status - {featuresSearchParams[i].objectName}
                    </CardHeader>
                    <React.Fragment>
                      <CardBody>
                        <h4 className={classes.cardTitle}>PLZ</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.plz)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.plzColorPrimary,
                            barColorPrimary: classes.plzBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.plz)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Stadt</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.city)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.cityColorPrimary,
                            barColorPrimary: classes.cityBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.city)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Deutschland</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.country)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.countryColorPrimary,
                            barColorPrimary: classes.countryBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.country)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                    </React.Fragment>
                  </Card>
                </GridItem>
              );
            })}
          {young_population.length > 0 &&
            checkedItems[2].isChecked &&
            young_population.map((item, i) => {
              return (
                <GridItem xs={12} sm={6} md={6} key={i}>
                  <Card>
                    <CardHeader color={color} stats>
                      Young Population - {featuresSearchParams[i].objectName}
                    </CardHeader>
                    <React.Fragment>
                      <CardBody>
                        <h4 className={classes.cardTitle}>PLZ</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.plz)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.plzColorPrimary,
                            barColorPrimary: classes.plzBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.plz)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Stadt</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.city)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.cityColorPrimary,
                            barColorPrimary: classes.cityBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.city)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Deutschland</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.country)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.countryColorPrimary,
                            barColorPrimary: classes.countryBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.country)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                    </React.Fragment>
                  </Card>
                </GridItem>
              );
            })}
          {urban.length > 0 &&
            checkedItems[3].isChecked &&
            urban.map((item, i) => {
              return (
                <GridItem xs={12} sm={6} md={6} key={i}>
                  <Card>
                    <CardHeader color={color} stats>
                      Urban - {featuresSearchParams[i].objectName}
                    </CardHeader>
                    <React.Fragment>
                      <CardBody>
                        <h4 className={classes.cardTitle}>PLZ</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.plz)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.plzColorPrimary,
                            barColorPrimary: classes.plzBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.plz)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Stadt</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.city)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.cityColorPrimary,
                            barColorPrimary: classes.cityBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.city)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Deutschland</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.country)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.countryColorPrimary,
                            barColorPrimary: classes.countryBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.country)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                    </React.Fragment>
                  </Card>
                </GridItem>
              );
            })}
        </div>
      </GridItem>
      {/*<GridItem xs={12} sm={12} md={4} lg={4}>*/}
      {/*  <Card>*/}
      {/*    <CardBody>*/}
      {/*      <p className={classes.infoText}>*/}
      {/*        Lorem Ipsum is simply dummy text of the printing and typesetting*/}
      {/*        typesetting industry. Lorem Ipsum has been the industry's standard*/}
      {/*        dummy text ever since the 1500s, when an unknown printer took a*/}
      {/*        galley of type and scrambled it to make a type specimen book. It*/}
      {/*        has survived not only five centuries, but also the leap into*/}
      {/*        electronic typesetting, remaining essentially unchanged.*/}
      {/*      </p>*/}
      {/*    </CardBody>*/}
      {/*  </Card>*/}
      {/*</GridItem>*/}
    </GridContainer>
  );
};

export default FeaturesCard;
