import { grayColor } from "../../../material-dashboard-react";

const suggestionsStyle = {
  wrapper: {
    position: "absolute",
    borderRadius: "4px",
    top: "80px",
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    zIndex: 9,
    border: "1px solid " + grayColor[4],
    padding: "8px"
  },
  item: {
    "&:hover": {
      backgroundColor: grayColor[10],
      cursor: "pointer"
    }
  }
};

export default suggestionsStyle;
