import actionTypes from "./market-analyse.types";

const initialState = {
  graphicsData: null,
  errorMessage: null,
  // LOADERS
  graphicsDataIsLoading: false
};

const marketAnalyseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_MARKET_ANALYSE_DATA:
      return initialState;
    case actionTypes.GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_START:
      return {
        ...state,
        graphicsDataIsLoading: true
      };
    case actionTypes.GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_SUCCESS:
      return {
        ...state,
        graphicsData: action.payload,
        graphicsDataIsLoading: false
      };
    case actionTypes.GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_FAILURE:
      return {
        graphicsDataIsLoading: false,
        errorMessage: action.payload
      };

    default:
      return state;
  }
};

export default marketAnalyseReducer;
