import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Map from "../../Search/Map";
import TableWithPagination from "../../Search/TableWithPagination";
import SelectedAddressModal from "../../../ui/Map/SelectedAddressModal";
import Loader from "../../../ui/Loader/Loader";
import * as actionCreator from "../../../store/actions";
import styles from "../../../assets/jss/material-dashboard-react/components/search/tabs/geographicStyle.js";

const useStyles = makeStyles(styles);

const Geographic = props => {
  const {
    loaders,
    results,
    searchFormParams,
    isSidebarToggled,
    isModalOpened,
    toggleModals,
    selectedMarker
  } = props;
  const classes = useStyles();
  return (
    <GridContainer>
      {isModalOpened && (
        <SelectedAddressModal
          isModalOpened={isModalOpened}
          isSidebarToggled={isSidebarToggled}
          marker={selectedMarker}
          onModalClose={() =>
            toggleModals({
              isModalOpened: false
            })
          }
        />
      )}
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <div className={classes.map}>
          {!loaders.statistic && results.totalResults !== "" ? (
            <Map
              center={{
                lat: searchFormParams.lat,
                lng: searchFormParams.lng
              }}
            />
          ) : (
            ""
          )}
        </div>
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ position: "relative" }}
      >
        {loaders.list ? <Loader /> : ""}
        {results.totalResults !== "" ? <TableWithPagination /> : ""}
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  const { search, results, ui } = state;

  return {
    searchFormParams: {
      lat: search.lat,
      lng: search.lng
    },

    results: {
      totalResults: results.totalResults
    },
    loaders: {
      list: ui.list,
      statistic: ui.statistic
    },
    isSidebarToggled: ui.isSidebarToggled,
    isModalOpened: ui.isModalOpened,
    selectedMarker: results.selectedMarker
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModals: value => dispatch(actionCreator.toggleModals(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Geographic);
