export default {
  root: {
    overflowY: "scroll",
    height: "500px",
    padding: "0 18px"
  },
  socLoader: {
    position: "relative",
    height: "400px",
    width: "100%"
  },
  customCard: {
    marginTop: "0 !important"
  },
  sociographic: { padding: "20px !important" },
  news: { position: "relative" },
  icon: { width: "1.5em" },
  classFlex: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%"
  }
};
