export const required = name => {
  return { required: true, message: `The ${name} field is required.` };
};

export const emailValidation = name => {
  return {
    validator(rule, value, callback) {
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        callback();
      } else {
        callback(`Enter correct ${name}.`);
      }
    }
  };
};
