import React from "react";
import ChartistGraph from "react-chartist";
import { makeStyles } from "@material-ui/core/styles";

import { dailySalesChart } from "../../variables/charts";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";

import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";

const useStyles = makeStyles(styles);

const Chart = props => {
  const classes = useStyles();
  const { type, color, title, subtitle, data, onChartClick } = props;
  const options = { ...dailySalesChart.options };

  let high = Math.max(...data.series[0]);
  let low = Math.min(...data.series[0]);

  high < 0 ? (high *= 0.9) : (high *= 1.2);
  low < 0 ? (low *= 1.2) : (low *= 0.9);

  if (low < 0) {
    low *= 1.2;
  }

  options.high = parseInt(high);
  options.low = parseInt(low);

  if (options.high === 0) {
    options.high++;
  }

  if (options.low === 0) {
    options.low--;
  }

  return (
    <Card chart className={props.className} onClick={onChartClick}>
      <CardHeader color={color}>
        <ChartistGraph
          className="ct-chart"
          data={data}
          type={type}
          options={options}
        />
      </CardHeader>
      <CardBody>
        <h4 className={classes.cardTitle}>{title}</h4>
        <p className={classes.cardCategory}>{subtitle}</p>
      </CardBody>
    </Card>
  );
};

export default Chart;
