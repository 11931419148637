import React from "react";

import Aux from "../../hoc/AuxReact";

import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CardTool from "../Search/CardTool";

const Statistic = props => {
  const { statisticData } = props;

  const separateValue = statisticData.separateValue;

  let percent = ((statisticData.rent * 12) / statisticData.sale) * 100;
  let percentBelow =
    ((statisticData.rentBelow * 12) / statisticData.saleBelow) * 100;
  let percentAbove =
    ((statisticData.rentAbove * 12) / statisticData.saleAbove) * 100;

  percent = Number.isNaN(percent) ? "-" : percent.toFixed(2);
  percentBelow = Number.isNaN(percentBelow) ? "-" : percentBelow.toFixed(2);
  percentAbove = Number.isNaN(percentAbove) ? "-" : percentAbove.toFixed(2);

  return (
    <Aux>
      <GridContainer>
        <GridItem xs={6} sm={6} md={4}>
          <CardTool
            color={"primary"}
            title={`${statisticData.sale || 0} €`}
            value={"KAUFPREIS /M²"}
          />
        </GridItem>
        {statisticData.rent && statisticData.category !== "Grundstücke" ? (
          <GridItem xs={6} sm={6} md={4}>
            <CardTool
              color={"primary"}
              title={`${statisticData.rent} €`}
              value={"MIETPREIS /M²"}
            />
          </GridItem>
        ) : (
          ""
        )}
        {statisticData.rent &&
        statisticData.sale &&
        statisticData.category !== "Grundstücke" ? (
          <GridItem xs={6} sm={6} md={4}>
            <CardTool
              color={"primary"}
              title={`${percent} %`}
              value={"DURSCHNITTSRENDITE %"}
            />
          </GridItem>
        ) : (
          ""
        )}
        {statisticData.saleBelow && parseInt(separateValue) !== 0 ? (
          <GridItem xs={6} sm={6} md={4}>
            <CardTool
              color={"primary"}
              title={`${statisticData.saleBelow} €`}
              value={`KAUFPREIS /M² (<${statisticData.separateValue}M²)`}
            />
          </GridItem>
        ) : (
          ""
        )}
        {statisticData.rentBelow &&
        parseInt(separateValue) !== 0 &&
        statisticData.category !== "Grundstücke" ? (
          <GridItem xs={6} sm={6} md={4}>
            <CardTool
              color={"primary"}
              title={`${statisticData.rentBelow} €`}
              value={`MIETPREIS /M² (<${statisticData.separateValue}M²)`}
            />
          </GridItem>
        ) : (
          ""
        )}
        {statisticData.rentBelow &&
        statisticData.rentAbove &&
        statisticData.category !== "Grundstücke" &&
        parseInt(separateValue) !== 0 ? (
          <GridItem xs={6} sm={6} md={4}>
            <CardTool
              color={"primary"}
              title={`${percentBelow} %`}
              value={`DURSCHNITTSRENDITE % (<${statisticData.separateValue}M²)`}
            />
          </GridItem>
        ) : (
          ""
        )}
        {statisticData.saleBelow &&
        statisticData.saleAbove &&
        parseInt(separateValue) !== 0 ? (
          <GridItem xs={6} sm={6} md={4}>
            <CardTool
              color={"primary"}
              title={`${statisticData.saleAbove} €`}
              value={`KAUFPREIS /M² (>${statisticData.separateValue}M²)`}
            />
          </GridItem>
        ) : (
          ""
        )}
        {statisticData.rentAbove &&
        parseInt(separateValue) !== 0 &&
        statisticData.category !== "Grundstücke" ? (
          <GridItem xs={6} sm={6} md={4}>
            <CardTool
              color={"primary"}
              title={`${statisticData.rentAbove} €`}
              value={`MIETPREIS /M² (>${statisticData.separateValue}M²)`}
            />
          </GridItem>
        ) : (
          ""
        )}
        {statisticData.rentAbove &&
        statisticData.rentBelow &&
        parseInt(separateValue) !== 0 &&
        statisticData.category !== "Grundstücke" ? (
          <GridItem xs={6} sm={6} md={4}>
            <CardTool
              color={"primary"}
              title={`${percentAbove} %`}
              value={`DURSCHNITTSRENDITE % (>${statisticData.separateValue}M²)`}
            />
          </GridItem>
        ) : (
          ""
        )}
      </GridContainer>
    </Aux>
  );
};

export default Statistic;
