import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../unitily";

const initialState = {
  totalProducts: 0,
  zipcodeValue: 0,
  today: 0,
  alarmCount: 0,
  rent: 0,
  sale: 0,
  all: 0
};

const set = (state, action) => {
  const updState = { ...state };
  updState[action.payload.property] = action.payload.value;

  return updateObject(state, {
    ...updState
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_SET:
      return set(state, action);
    default: {
      return state;
    }
  }
};

export default reducer;
