import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Backdrop from "../ui/Backdrop/Backdrop";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "../routes/routes";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";
import AddForm from "../components/Notifications/AddForm";
import Modal from "ui/Modal/Modal.js";
import * as actionCreator from "../store/actions";
import ProductApi from "../api/Product";

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

const sidebarRoutes = [];

routes.map(route => {
  if (route.icon !== undefined) {
    sidebarRoutes.push(route);
  }

  return true;
});

const useStyles = makeStyles(styles);

const Admin = ({
  toggleMultiple,
  openModal,
  toggleSidebar,
  isSidebarToggled,
  changeSearchParam,
  categories,
  toggleLoading,
  ...rest
}) => {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image] = React.useState(bgImage);
  const [color] = React.useState("blue");
  const state = useSelector(state => state);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  const handleClickAddNotification = () => {
    toggleMultiple({ openModal: !openModal });
  };

  React.useEffect(() => {
    toggleLoading("loading", true);
    if (categories.length === 0) {
      ProductApi.getTypes().then(resp => {
        changeSearchParam("categories", resp.data.data);
        toggleLoading("loading", false);
      });
    }
    // eslint-disable-next-line
  }, [toggleLoading]);

  const mainPanelClasses = classNames({
    [classes.mainPanel]: true,
    [classes.mainPanelFull]: isSidebarToggled,
    [classes.mainPanelShort]: !isSidebarToggled
  });

  return (
    <div className={classes.wrapper}>
      <Modal
        title={"Add an Alert"}
        open={openModal}
        handleClose={handleClickAddNotification}
        FormComponent={AddForm}
      />
      <Sidebar
        routes={sidebarRoutes}
        logoText={"FCRImmo"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        onToggleSidebar={toggleSidebar}
        open={mobileOpen}
        color={color}
        isToggled={isSidebarToggled}
        {...rest}
      />
      <div
        className={mainPanelClasses}
        ref={mainPanel}
        id="fcrimmo-perfect-scrollbar"
      >
        <Navbar
          routes={sidebarRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              {state.ui.loading ? (
                <Backdrop isSidebarToggled={isSidebarToggled}>
                  <h2>Loading...</h2>
                </Backdrop>
              ) : (
                ""
              )}

              {switchRoutes}
            </div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    openModal: state.ui.openModal,
    isSidebarToggled: state.ui.isSidebarToggled,
    categories: state.search.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleMultiple: value => dispatch(actionCreator.toggleMultiple(value)),
    toggleSidebar: () => dispatch(actionCreator.toggleSidebar()),
    changeSearchParam: (property, value) =>
      dispatch(actionCreator.changeSearchParam(property, value)),
    toggleLoading: (property, value) =>
      dispatch(actionCreator.toggleLoadding(property, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
