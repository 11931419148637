import React from "react";
import { Alert } from "@material-ui/lab";

export default function AlertCustom({ message, type }) {
  return (
    <Alert variant="filled" severity={type}>
      {message}
    </Alert>
  );
}
