/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

// REDUX STORE
import store from "./store/store";

// core components
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth";

import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();

let routes;

const token = window.localStorage.getItem("token");

if (token == null) {
  routes = (
    <Switch>
      <Route path="/auth" component={Auth} />
      <Redirect from="/" to="/auth/login" />
    </Switch>
  );
} else {
  routes = (
    <Switch>
      <Route path="/admin" component={Admin} />
      <Redirect from="/" to="/admin/dashboard" />
    </Switch>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>{routes}</Router>
  </Provider>,
  document.getElementById("root")
);
