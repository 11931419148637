import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginTop: "22px",
    "& > *": {
      margin: theme.spacing(1),
      borderRadius: "4px"
    }
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20)
  },
  fullWidth: {
    width: "100%",
    height: "auto"
  }
}));

const SqrtAvatar = props => {
  const classes = useStyles();
  const { src, alt, fullWidth } = props;

  const size = fullWidth ? classes.fullWidth : classes.large;

  return (
    <div className={classes.root}>
      <Avatar
        alt={alt}
        src={src}
        className={size}
        style={{
          margin: 0
        }}/>
    </div>
  );
};

export default SqrtAvatar;
