import React from "react";
import { connect } from "react-redux";

import Aux from "../../hoc/AuxReact";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Form from "../../components/Variance/Form";
import GrowthRate from "../../components/Variance/GrowthRate";
import Loader from "../../ui/Loader/Loader";
import * as actionCreator from "../../store/actions";
import TypeGrowthRate from "../../components/Variance/TypeGrowthRate";

const Variance = props => {
  return (
    <Aux>
      <GridContainer>
        <GridItem
          xs={12}
          style={{
            position: "relative"
          }}
        >
          {props.topGrowthLoader ? <Loader /> : ""}
          <GrowthRate />
        </GridItem>
        <GridItem xs={12}>
          <Form />
        </GridItem>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem
              xs={12}
              md={12}
              lg={6}
              style={{
                position: "relative"
              }}
            >
              {props.saleGrowthLoader ? <Loader /> : ""}
              <TypeGrowthRate
                title={"Kaufen"}
                color={"warning"}
                value={"saleGrowth"}
              />
            </GridItem>
            <GridItem
              xs={12}
              md={12}
              lg={6}
              style={{
                position: "relative"
              }}
            >
              {props.rentGrowthLoader ? <Loader /> : ""}
              <TypeGrowthRate
                title={"Mieten"}
                color={"danger"}
                value={"rentGrowth"}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Aux>
  );
};

const mapStateToProps = state => {
  return {
    topGrowth: state.variance.topGrowth,
    topGrowthLoader: state.ui.topGrowth,
    saleGrowthLoader: state.ui.saleGrowth,
    rentGrowthLoader: state.ui.rentGrowth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleLoading: (property, value) =>
      dispatch(actionCreator.toggleLoadding(property, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Variance);
