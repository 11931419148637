import React, { Component } from "react";
import { InfoWindow, Marker } from "react-google-maps";

class InfoWindowMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      icon: ""
    };
  }

  componentDidMount() {
    this.loadIcon(this.props.item.icon);
  }

  handleToggleOpen = () => {
    this.setState({
      isOpen: true
    });
  };

  handleToggleClose = () => {
    this.setState({
      isOpen: false
    });
  };

  loadIcon = iconPath => {
    if (iconPath) {
      import(`../../assets${iconPath}`).then(icon => {
        this.setState({ icon: icon.default });
      });
    }
  };

  render() {
    const item = this.props.item;
    const { icon } = this.state;
    return (
      <Marker
        key={this.props.index}
        position={{ lat: this.props.lat, lng: this.props.lng }}
        icon={icon}
        onClick={() => this.handleToggleOpen()}
      >
        {this.state.isOpen && this.props.isInfoWindow === true && (
          <InfoWindow onCloseClick={this.handleToggleClose}>
            <div onClick={() => this.props.onWindowClick(item)}>
              {item.product_area ? <p>Area: {item.product_area}</p> : ""}
              {item.product_name ? <p>Name: {item.product_name}</p> : ""}
              {item.product_zip ? <p>Zip: {item.product_zip}</p> : ""}
              {item.str_name ? <p>Street Name: {item.str_name}</p> : ""}
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default InfoWindowMap;
