const searchStyle = {
  typo: {
    marginBottom: "40px",
    position: "relative"
  },
  searchResult: {
    position: "relative"
  }
};

export default searchStyle;
