import React from "react";
import { connect } from "react-redux";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import NotificationTable from "../../components/Notifications/Table";

import Aux from "../../hoc/AuxReact";

const Notification = props => {
  return (
    <Aux>
      <GridContainer>
        <GridItem xs={12}>
          <NotificationTable />
        </GridItem>
      </GridContainer>
    </Aux>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
