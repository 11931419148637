import api from "../config/api";

const AuthService = {
  login(email, password) {
    return api.post("login", {
      email: email,
      password: password
    });
  },

  logout() {
    return api.post("logout", {});
  },

  changePassword(password, passwordConfirmation) {
    return api.post("change-password", {
      password: password,
      password_confirmation: passwordConfirmation
    });
  }
};

export default AuthService;
