import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { TablePagination, MenuItem } from "@material-ui/core";

import Table from "../Table/Table.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter";
import TablePaginationActions from "../PaginatorActions/PaginatorActions";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CustomSelect from "../CustomSelect/CustomSelect";

import Popover from "../Popover/Popover";

import { years, months } from "../../config/year-month";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

const PaginatedTable = props => {
  const classes = useStyles();
  const {
    onCloseTitleFilter,
    onFilterChanged,
    onRowsPerPageChanged,
    onChangeOrder,
    page,
    onPageChanged,
    rowsPerPage,
    rows,
    separateValue,
    count,
    changeSerchParam,
    filterYear,
    filterMonth
  } = props;
  const { site, type, categoryValue, status } = props.filterParams;

  const numberWithCommas = x =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const onYearMonthSelect = (value, property) => {
    let filteredDate = "";
    if (value === "all") {
      onFilterChanged("all", "month");
      changeSerchParam("filterYear", "all");
      changeSerchParam("filterMonth", "all");
    } else {
      if (property === "filterYear") {
        filteredDate =
          filterMonth !== "all" ? `${filterMonth} ${value}` : "all";
        changeSerchParam("filterYear", value);
        onFilterChanged(filteredDate, "month");
      }
      if (property === "filterMonth") {
        filteredDate = filterYear !== "all" ? `${value} ${filterYear}` : "all";
        changeSerchParam("filterMonth", value);
        onFilterChanged(filteredDate, "month");
      }
    }
  };
  const data = [];

  rows.map((prop, index) => {
    const name = {
      label: prop.product_name,
      url: `/admin/property-view/${prop.product_id}`,
      urlComesFrom: prop.product_url
    };
    const yearConstructed = {
      label: prop.product_year_constructed
    };
    const typeProperty = {
      label: prop.type_property
    };
    const typeNameProperty = {
      label: prop.product_type
    };
    const coveredArea = {
      label: prop.product_covered_area + " m²"
    };
    const address = {
      label: prop.product_address
    };
    const mietrendite = {
      label:
        prop.product_type === "Grundstücke" ||
        prop.product_type === "Gewerbegrundstücke" ||
        prop.type_property === "Mieten"
          ? "k.A.!"
          : prop.mietrendite + " %"
    };
    const price = {
      label: numberWithCommas(prop.product_price_int) + "€"
    };
    const priceForM2 = {
      label:
        prop.product_covered_area !== 0
          ? numberWithCommas(
              (prop.product_price_int / prop.product_covered_area).toFixed(2)
            ) + "€"
          : // : "€ / m²"
            0
    };
    const status = {
      label: prop.product_status === 1 ? "Activ" : "Nicht aktiv"
    };
    const isOnline = {
      label:
        !prop.status_changed_at && prop.product_status === 1
          ? "Online"
          : prop.status_changed_at || "Offline"
    };
    // const avgAvgZip = {
    //   label: parseFloat(prop.avg_zip).toFixed(2) + " EUR"
    // };

    if (data[index] === undefined) {
      data[index] = [];
    }
    return data[index].push(
      {
        label: page * rowsPerPage + index + 1
      },
      name,
      yearConstructed,
      typeProperty,
      typeNameProperty,
      coveredArea,
      address,
      mietrendite,
      price,
      priceForM2,
      status,
      // avgAvgZip,
      isOnline
    );
  });
  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>List of properties</h4>
        <p className={classes.cardCategoryWhite}>Gesamt: {count}</p>
      </CardHeader>
      <CardBody>
        <GridContainer justify={"center"}>
          <GridItem md={2}>
            <CustomSelect
              labelText="Years"
              id="year"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event =>
                  onYearMonthSelect(event.target.value, "filterYear"),
                value: filterYear
              }}
            >
              {years.map(y => (
                <MenuItem value={y.value} key={y.id}>
                  {y.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
          <GridItem md={2}>
            <CustomSelect
              labelText="Months"
              id="month"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event =>
                  onYearMonthSelect(event.target.value, "filterMonth"),
                value: filterMonth
              }}
            >
              {months.map(m => (
                <MenuItem value={m.value} key={m.id}>
                  {m.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
          <GridItem md={2}>
            <CustomSelect
              labelText="Site"
              id="site"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => onFilterChanged(event.target.value, "site"),
                value: site
              }}
            >
              <MenuItem value={"all"} defaultChecked>
                Alle
              </MenuItem>
              <MenuItem value={"immowelt"}>Immowelt</MenuItem>
              <MenuItem value={"immobilienscout"}>Immobilienscout 24</MenuItem>
              <MenuItem value={"gewerbeimmobilien.jll"}>JLL</MenuItem>
              <MenuItem value={"neubaukompass"}>Neubaukompass</MenuItem>
              <MenuItem value={"immobilien.sparkasse"}>
                Sparkassen Immobilien
              </MenuItem>
              <MenuItem value={"ebay-kleinanzeigen"}>
                Ebay-Kleinanzeigen
              </MenuItem>
              <MenuItem value={"immobilien.vr"}>VR-Banken</MenuItem>
            </CustomSelect>
          </GridItem>
          <GridItem md={2}>
            <CustomSelect
              labelText="Type"
              id="type"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => onFilterChanged(event.target.value, "type"),
                value: type
              }}
            >
              <MenuItem value={"all"} defaultChecked>
                Alle
              </MenuItem>
              <MenuItem value={"mieten"}>Mieten</MenuItem>
              <MenuItem value={"kaufen"}>Kaufen</MenuItem>
            </CustomSelect>
          </GridItem>
          {parseInt(separateValue) !== 0 ? (
            <GridItem md={2}>
              <CustomSelect
                labelText="By value"
                id="value"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event =>
                    onFilterChanged(event.target.value, "categoryValue"),
                  value: categoryValue
                }}
              >
                <MenuItem value={"all"} defaultChecked>
                  Alle
                </MenuItem>
                <MenuItem value={"more"}>Mehr {separateValue} m²</MenuItem>
                <MenuItem value={"less"}>Weniger {separateValue} m²</MenuItem>
              </CustomSelect>
            </GridItem>
          ) : (
            ""
          )}
          <GridItem md={2}>
            <CustomSelect
              labelText="Status"
              id="status"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event =>
                  onFilterChanged(event.target.value, "status"),
                value: status
              }}
            >
              <MenuItem value={"all"}>Alle Produkte</MenuItem>
              <MenuItem value={"active"} defaultChecked>
                Aktive Produkte
              </MenuItem>
              <MenuItem value={"inactive"}>Inaktive Produkte</MenuItem>
            </CustomSelect>
          </GridItem>
          <GridItem md={12}>
            <Popover onClose={onCloseTitleFilter} />
          </GridItem>
        </GridContainer>
        <Table
          tableHeaderColor="primary"
          tableHead={[
            {
              label: "#"
            },
            {
              label: "Name"
              // key: "product_name"
            },
            {
              label: "Year Constructed",
              key: "product_year_constructed"
            },
            {
              label: "Typ",
              key: "type_property"
            },
            {
              label: "Kategorie",
              key: "product_type"
            },
            {
              label: "Fläche",
              key: "product_covered_area"
            },
            {
              label: "Ort",
              key: "product_address"
            },
            {
              label: "Mietrendite",
              key: "mietrendite"
            },
            {
              label: "Preis",
              key: "product_price_int"
            },
            {
              label: "m² Preis",
              key: "price_per_m2"
            },
            {
              label: "Status",
              key: "product_status"
            },
            // {
            //   label: "Avg Zipcode Price"
            //   // key: "is",
            // },
            {
              label: "Is Online"
              // key: "is"
            }
            // "Optionen"
          ]}
          tableData={data}
          onChangeOrder={onChangeOrder}
          sortAble
        />
      </CardBody>
      <CardFooter>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false
          }}
          onChangePage={onPageChanged}
          onChangeRowsPerPage={onRowsPerPageChanged}
          ActionsComponent={TablePaginationActions}
        />
      </CardFooter>
    </Card>
  );
};

export default PaginatedTable;
