import React, { Component } from "react";

import Auth from "../views/Auth/Auth";

class AuthPage extends Component {
  render() {
    return <Auth />;
  }
}

export default AuthPage;
