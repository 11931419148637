const NearByInfoService = {
  fetch(hierarchy) {
    const keys = Object.keys(hierarchy);
    const locations = [];

    keys.map(key => {
      let counter = 0;
      let prevTableName = "";
      locations[key] = [];

      for (let i = 0; i < hierarchy[key].length; i++) {
        let item = hierarchy[key][i];
        if (item.distance <= 2000 && prevTableName !== item.localname) {
          counter++;

          locations[key].push(item.localname);
          locations[key].push(item.distance / 1000).toFixed(2);
          prevTableName = item.localname;

          if (counter >= 4) break;
        }
      }

      return true;
    });

    return locations;
  },
  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d.toFixed(2);
  },
  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
};

export default NearByInfoService;
