import * as actionTypes from "./actionTypes";

export const toggleLoadding = (property, value = false) => {
  return {
    type: actionTypes.LOADING,
    payload: {
      property,
      value
    }
  };
};

export const toggleMultiple = value => {
  return {
    type: actionTypes.LOADING_MULTIPLE,
    payload: {
      value
    }
  };
};

export const toggleModals = value => {
  return {
    type: actionTypes.TOGGLE_MODALS,
    payload: {
      value
    }
  };
};

export const toggleSidebar = () => {
  return {
    type: actionTypes.TOGGLE_SIDEBAR
  };
};

export const toggleDynamicLoaders = (property, value, index) => {
  return {
    type: actionTypes.TOGGLE_DYNAMIC_LOADERS,
    payload: {
      property,
      value,
      index
    }
  };
};
