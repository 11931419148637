import * as actionTypes from './actionTypes';

export const dashboardSet = (property, value) => {
    return {
        type: actionTypes.DASHBOARD_SET,
        payload: {
            property,
            value
        }
    };
};