import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Input
} from "@material-ui/core";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    selectProps,
    error,
    success,
    message,
  } = props;
  const { onChange, value } = props.inputProps;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  const messages = [];

  if (error && message) {
    Object.keys(message).map(key => {
      messages.push(message[key]);

      return true;
    });
  }

  return (
    <FormControl
      className={formControlProps.className + " " + classes.formControl}
      {...formControlProps}
      error={message && !!message.error}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        {...selectProps}
        onChange={onChange}
        classes={{
          root: marginTop,
          disabled: classes.disabled
        }}
        input={
          <Input
            classes={{
              underline: underlineClasses
            }}
          />
        }
        id={id}
        value={[value]}
      >
        {props.children}
      </Select>
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
      {error && message ? (
        messages.map((m, index) => (
          <FormHelperText key={`helper-${index}`} error>
            {m}
          </FormHelperText>
        ))
      ) : (
        <FormHelperText>&nbsp;</FormHelperText>
      )}
    </FormControl>
  );
}

CustomSelect.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.object
};
