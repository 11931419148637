import * as actionTypes from "./actionTypes";

export const changeVarianceSearchParam = (property, value) => {
  return {
    type: actionTypes.CHANGE_VARIANCE_SEARCH_PARAM,
    payload: {
      property,
      value
    }
  };
};

export const changeGrowthRateCount = (property, value) => {
  return {
    type: actionTypes.CHANGE_GROWTH_COUNT,
    payload: {
      property,
      value
    }
  };
};

export const onChangeBtnClick = () => {
  return {
    type: actionTypes.CHANGE_BTN_CLICK
  };
};

export const save = value => {
  return {
    type: actionTypes.VARIANCE_SET,
    payload: {
      value
    }
  };
};
