export const months = [
  { id: 0, name: "Alle", value: "all" },
  { id: 1, name: "January", value: "Jan" },
  { id: 2, name: "February", value: "Feb" },
  { id: 3, name: "March", value: "Mar" },
  { id: 4, name: "April", value: "Apr" },
  { id: 5, name: "May", value: "May" },
  { id: 6, name: "June", value: "Jun" },
  { id: 7, name: "July", value: "Jul" },
  { id: 8, name: "August", value: "Aug" },
  { id: 9, name: "September", value: "Sep" },
  { id: 10, name: "October", value: "Oct" },
  { id: 11, name: "November", value: "Nov" },
  { id: 12, name: "December", value: "Dec" }
];

export const years = [
  { id: 0, name: "Alle", value: "all" },
  { id: 1, name: "2017", value: "17" },
  { id: 2, name: "2018", value: "18" },
  { id: 3, name: "2019", value: "19" },
  { id: 4, name: "2020", value: "20" }
];
