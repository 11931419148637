import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { MenuItem } from "@material-ui/core";

import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CustomSelect from "../CustomSelect/CustomSelect";
import Card from "../Card/Card";
import Button from "../CustomButtons/Button";

import * as VarianceAPI from "../../api/Variance";

import * as actionCreator from "../../store/actions";
import CustomInput from "../CustomInput/CustomInput";

const Form = props => {
  const [categories, setCategories] = useState([]);
  const [isBtnDisabled] = useState(false);
  const {
    toggleMultiple,
    set,
    changeVarianceSearchParam,
    varianceSearchFormParams,
    changeGrowthRateCount,
    onChangeBtnClick
  } = props;
  const {
    growthRate,
    radius,
    category,
    sizeFrom,
    sizeTo,
    priceFrom,
    priceTo,
    yearFrom,
    yearTo,
    rentalPercent
  } = varianceSearchFormParams;
  const typeName = category === "Alle" ? null : category;

  useEffect(() => {
    setCategories(props.categories.map(item => item.type_name));
  }, [props.categories]);

  const onSubmitHandler = () => {
    const params = {
      ...varianceSearchFormParams
    };
    if (params.radius === "") {
      changeVarianceSearchParam(0, "radius");
      params.radius = 0;
    }

    onChangeBtnClick();
    toggleMultiple({
      saleGrowth: true,
      rentGrowth: true
    });

    VarianceAPI.zipcodeGrowthRate(1, 10, "Kaufen", typeName, params)
      .then(resp => {
        changeGrowthRateCount(resp.data.data.total, "saleGrowthCount");
        return resp.data.data;
      })
      .then(data => {
        toggleMultiple({
          saleGrowth: false
        });

        set({
          saleGrowth: data.data
        });
      });

    VarianceAPI.zipcodeGrowthRate(1, 10, "Mieten", typeName, params)
      .then(resp => {
        changeGrowthRateCount(resp.data.data.total, "rentGrowthCount");
        return resp.data.data;
      })
      .then(data => {
        toggleMultiple({
          rentGrowth: false
        });

        set({
          rentGrowth: data.data
        });
      });
  };

  return (
    <Card>
      <CardHeader color={"primary"}>Search console</CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={9}>
            <CustomInput
              labelText="Growth Rate"
              id="growthRate"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "number",
                onChange: event =>
                  changeVarianceSearchParam(event.target.value, "growthRate"),
                value: growthRate
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CustomInput
              labelText="Umkreis, (km)"
              id="radius"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "number",
                onChange: event =>
                  changeVarianceSearchParam(event.target.value, "radius"),
                value: radius
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <CustomSelect
              labelText="Category"
              id="category"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event =>
                  changeVarianceSearchParam(event.target.value, "category"),
                value: category
              }}
            >
              {categories.map((cat, index) => (
                <MenuItem key={`category-${index}`} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CustomInput
              labelText="Mietrendite, (%)"
              id="rental-yield"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "number",
                onChange: event =>
                  changeVarianceSearchParam(
                    event.target.value,
                    "rentalPercent"
                  ),
                value: rentalPercent
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="size / from"
                  id="size-from"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event =>
                      changeVarianceSearchParam(event.target.value, "sizeFrom"),
                    value: sizeFrom
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="size / to"
                  id="size-to"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event =>
                      changeVarianceSearchParam(event.target.value, "sizeTo"),
                    value: sizeTo
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="price / from"
                  id="price-from"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event =>
                      changeVarianceSearchParam(
                        event.target.value,
                        "priceFrom"
                      ),
                    value: priceFrom
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="price / to"
                  id="price-to"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event =>
                      changeVarianceSearchParam(event.target.value, "priceTo"),
                    value: priceTo
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="year / from"
                  id="year-from"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event =>
                      changeVarianceSearchParam(event.target.value, "yearFrom"),
                    value: yearFrom
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="year / to"
                  id="year-to"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event =>
                      changeVarianceSearchParam(event.target.value, "yearTo"),
                    value: yearTo
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12}>
            <Button
              onClick={onSubmitHandler}
              color={"primary"}
              round
              disabled={isBtnDisabled}
            >
              Search
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = state => {
  const { variance, ui, search } = state;
  return {
    loader: ui.loading,
    saleGrwothLoader: ui.saleGrowth,
    rentGrwothLoader: ui.rentGrowth,
    varianceSearchFormParams: {
      growthRate: variance.growthRate,
      radius: variance.radius,
      category: variance.category,
      sizeFrom: variance.sizeFrom,
      sizeTo: variance.sizeTo,
      priceFrom: variance.priceFrom,
      priceTo: variance.priceTo,
      yearFrom: variance.yearFrom,
      yearTo: variance.yearTo,
      rentalPercent: variance.rentalPercent,
      month: "all",
      site: "all",
      categoryValue: "all"
    },
    categories: search.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleLoading: (property, value) =>
      dispatch(actionCreator.toggleLoadding(property, value)),
    set: value => dispatch(actionCreator.save(value)),
    toggleMultiple: value => dispatch(actionCreator.toggleMultiple(value)),
    changeVarianceSearchParam: (property, value) =>
      dispatch(actionCreator.changeVarianceSearchParam(property, value)),
    changeGrowthRateCount: (property, value) =>
      dispatch(actionCreator.changeGrowthRateCount(property, value)),
    onChangeBtnClick: () => dispatch(actionCreator.onChangeBtnClick())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
