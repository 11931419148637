import * as actionTypes from "./actionTypes";

export const changeSearchParam = (property, value) => {
  return {
    type: actionTypes.CHANGE_SEARCH_PARAMETER,
    payload: {
      property,
      value
    }
  };
};

export const changeAddress = value => {
  return {
    type: actionTypes.CHANGE_ADDRESS,
    payload: {
      value
    }
  };
};

export const resetSearchParams = () => {
  return {
    type: actionTypes.RESET_PARAMS
  };
};
