import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import style from "../../assets/jss/material-dashboard-react/components/notifications/linkStyle";

const useStyles = makeStyles(style);

const Link = props => {
  const { title, onClick, color } = props;
  const classes = useStyles();
  const linkClasses = classNames({
    [classes[color]]: color,
    [classes.link]: true
  });

  return (
    <div className={classes.root}>
      <a href={"/"} onClick={onClick} className={linkClasses}>
        {title}
      </a>
    </div>
  );
};

export default Link;
