import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../unitily";

const url_string = window.location.href;
const url = new URL(url_string);

const initState = {
  lat: "",
  lng: "",
  address: url.searchParams.get("zip") || "",
  radius: url.searchParams.get("distance") || "",
  category: url.searchParams.get("type_name") || "Wohnung",
  rentalPercent: url.searchParams.get("mietrendite") || "",
  sizeFrom: url.searchParams.get("sizeFrom") || "",
  sizeTo: url.searchParams.get("sizeTo") || "",
  priceFrom: url.searchParams.get("priceFrom") || "",
  priceTo: url.searchParams.get("priceTo") || "",
  yearFrom: url.searchParams.get("yearFrom") || "",
  yearTo: url.searchParams.get("yearTo") || "",
  separateValue: url.searchParams.get("separateValue") || 40,
  zipcode: "",
  areaType: "",
  rowsPerPage: 10,
  page: 0,
  month: "all",
  site: "all",
  type: "kaufen",
  categoryValue: "Wohnung",
  titleValue: ["Erbpacht", "Erbbaurecht"],
  status: "active",
  rent_general: "",
  suggestions: [],
  categories: [],
  features: [],
  varianceZipCode: url.searchParams.get("variance_zipcode") || "",
  filterYear: "all",
  filterMonth: "all"
};

const changeSearchParamenter = (state, action) => {
  const updState = { ...state };
  updState[action.payload.property] = action.payload.value;

  return updateObject(state, {
    ...updState
  });
};

const changeAddress = (state, action) => {
  const address = action.payload.value;
  let suggestions = [];

  return updateObject(state, {
    address: address,
    suggestions: suggestions
  });
};

const resetParams = () => {
  return { ...initState };
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_SEARCH_PARAMETER:
      return changeSearchParamenter(state, action);
    case actionTypes.CHANGE_ADDRESS:
      return changeAddress(state, action);
    case actionTypes.RESET_PARAMS:
      return resetParams();
    default:
      return state;
  }
};

export default reducer;
