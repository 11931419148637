import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  grid: {
    padding: "0 15px !important"
  },
  relative: {
    position: "relative"
  }
};

const useStyles = makeStyles(styles);

export default function GridItem(props) {
  const classes = useStyles();
  const { children, relative, className, ...rest } = props;
  const gridClasses = classNames({
    [classes.grid]: true,
    [className]: className !== undefined,
    [classes.relative]: !!relative
  });

  return (
    <Grid item {...rest} className={gridClasses}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  children: PropTypes.node
};
