import React, { forwardRef } from "react";

import { MenuItem } from "@material-ui/core";
import Switcher from "../Switcher/Switcher";

const CustomItem = ({ title, isChecked, onSwitch }, ref) => (
  <MenuItem>
    <Switcher
      innerRef={ref}
      title={title}
      isChecked={isChecked}
      onSwitch={onSwitch}
    />
  </MenuItem>
);

export default forwardRef(CustomItem);
