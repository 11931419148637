import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { slider } from "../../config/slick";
import { Typography } from "@material-ui/core";
import SqrtAvatar from "../Avatar/SqrtAvatar";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CustomSlider = ({ images, title }) => {
  const settings = {
    ...slider,
    // eslint-disable-next-line react/display-name
    customPaging: index => <img src={images[index]} alt={`img-${index}`} />
  };
  return (
    <div>
      <Typography variant={"h5"} gutterBottom>
        {title}
      </Typography>
      <Slider {...settings}>
        {images &&
          images.length > 0 &&
          images.map((img, index) => (
            <SqrtAvatar
              key={`slider-item-${index}`}
              alt={`Slider image ${index}`}
              src={img}
              fullWidth
            />
          ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;

Slider.propTypes = {
  title: PropTypes.string,
  images: PropTypes.array
};
