// actions for auth
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const PROPERTY_CHANGE = "PROPERTY_CHANGE";

// actions for sidebar
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

// actions for dashboard
export const DASHBOARD_SET = "DASHBOARD_SET";

// actions for ui
export const LOADING = "LOADING";
export const LOADING_MULTIPLE = "LOADING_MULTIPLE";
export const TOGGLE_MODALS = "TOGGLE_MODALS";
export const TOGGLE_DYNAMIC_LOADERS = "TOGGLE_DYNAMIC_LOADERS";

// actions for search
export const CHANGE_SEARCH_PARAMETER = "CHANGE_SEARCH_PARAMETER";
export const CHANGE_ADDRESS = "CHANGE_ADDRESS";
export const RESET_PARAMS = "RESET_PARAMS";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";

// actions for results
export const RESULTS_SET = "RESULTS_SET";
export const RESULTS_MULTIPLE = "RESULTS_MULTIPLE";
export const RESET_RESULTS = "RESET_RESULTS";

// actions for variance
export const VARIANCE_SET = "VARIANCE_SET";
export const CHANGE_VARIANCE_SEARCH_PARAM = "CHANGE_VARIANCE_SEARCH_PARAM";
export const CHANGE_GROWTH_COUNT = "CHANGE_GROWTH_COUNT";
export const CHANGE_BTN_CLICK = "CHANGE_BTN_CLICK";

// actions for notification
export const NOTIFICATION_SET = "NOTIFICATION_SET";

// action types for features search
export const SET_FEATURES_SEARCH_RESULTS = "SET_FEATURES_SEARCH_RESULTS";
export const SET_FEATURES_SEARCH_PARAM = "SET_FEATURES_SEARCH_PARAM";
export const CHANGE_FEATURES_SEARCH_PARAM = "CHANGE_FEATURES_SEARCH_PARAM";
