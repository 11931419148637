import api from "../config/api";

const Sociographic = {
  get(params) {
    return api.get("statistic/sociographic", {
      params: {
        ...params
      }
    });
  }
};

export default Sociographic;
