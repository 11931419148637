import React from "react";

import Button from "../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

export default function FormDialog({
  title,
  subTitle,
  open,
  handleClose,
  FormComponent
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subTitle}</DialogContentText>
          {FormComponent ? <FormComponent /> : ""}
        </DialogContent>
        {FormComponent ? (
          ""
        ) : (
          <DialogActions>
            <Button size={"sm"} color="white" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
