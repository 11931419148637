import * as actionTypes from "../actions/actionTypes";
import * as commonReducer from "./common";
import { updateObject } from "../unitily";

const initialState = {
  topGrowth: [],
  saleGrowth: [],
  rentGrowth: [],
  growthRate: "",
  radius: 0,
  category: "Wohnung",
  sizeFrom: "",
  sizeTo: "",
  priceFrom: "",
  priceTo: "",
  yearFrom: "",
  yearTo: "",
  rentalPercent: "",
  rowsPerPage: 10,
  page: 0,
  month: "all",
  site: "all",
  type: "kaufen",
  categoryValue: "all",
  counts: {
    rentGrowthCount: 0,
    saleGrowthCount: 0
  },
  isBtnClicked: false
};

const changeVarianceSearchParam = (state, action) => {
  const updState = { ...state };
  updState[action.payload.value] = action.payload.property;

  return updateObject(state, {
    ...updState
  });
};

const changeGrowthRateCount = (state, action) => {
  const updState = { ...state };
  updState.counts[action.payload.value] = action.payload.property;

  return updateObject(state, {
    ...updState
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VARIANCE_SET:
      return commonReducer.save(state, action);
    case actionTypes.CHANGE_VARIANCE_SEARCH_PARAM:
      return changeVarianceSearchParam(state, action);
    case actionTypes.CHANGE_GROWTH_COUNT:
      return changeGrowthRateCount(state, action);
    case actionTypes.CHANGE_BTN_CLICK:
      return { ...state, isBtnClicked: true };
    default:
      return state;
  }
};

export default reducer;
