const SelectedAddressService = {
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  getRowData(marker) {
    const data = [];
    marker.map((prop, index) => {
      const name = {
        label: prop.product_name,
        url: `/admin/property-view/${prop.product_id}`,
        urlComesFrom: prop.product_url
      };
      const yearConstructed = {
        label: prop.product_year_constructed
      };
      const typeProperty = {
        label: prop.type_property
      };
      const typeNameProperty = {
        label: prop.product_type
      };
      const coveredArea = {
        label: prop.product_covered_area + " m²"
      };
      const address = {
        label: prop.product_address
      };
      const mietrendite = {
        label:
          prop.product_type === "Grundstücke" ||
          prop.product_type === "Gewerbegrundstücke" ||
          prop.type_property === "Mieten" ||
          prop.mietrendite === null
            ? "k.A.!"
            : prop.mietrendite + " %"
      };
      const price = {
        label: this.numberWithCommas(prop.product_price_int) + "€"
      };
      const priceForM2 = {
        label:
          prop.product_covered_area !== 0
            ? this.numberWithCommas(
                (prop.product_price_int / prop.product_covered_area).toFixed(2)
              ) + "€"
            : 0
      };
      const status = {
        label: prop.product_status === 1 ? "Activ" : "Nicht aktiv"
      };
      const isOnline = {
        label:
          !prop.status_changed_at && prop.product_status === 1
            ? "Online"
            : prop.status_changed_at || "Offline"
      };

      if (data[index] === undefined) {
        data[index] = [];
      }
      return data[index].push(
        name,
        yearConstructed,
        typeProperty,
        typeNameProperty,
        coveredArea,
        address,
        mietrendite,
        price,
        priceForM2,
        status,
        isOnline
      );
    });
    return data;
  },
  tableHeadItems: [
    {
      label: "Name"
    },
    {
      label: "Year Constructed"
    },
    {
      label: "Typ"
    },
    {
      label: "Kategorie"
    },
    {
      label: "Fläche"
    },
    {
      label: "Ort"
    },
    {
      label: "Mietrendite"
    },
    {
      label: "Preis"
    },
    {
      label: "m² Preis"
    },
    {
      label: "Status"
    },
    {
      label: "Is Online"
    }
  ]
};

export default SelectedAddressService;
