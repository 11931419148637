import { primaryColor } from "../../material-dashboard-react";

const loaderStyle = {
  container: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9
  },
  root: {
    display: "block",
    margin: "0 auto",
    padding: "60px",
    marginTop: "6%"
  },
  blur: {
    height: "100%",
    position: "absolute",
    width: "100%",
    backgroundColor: "rgba(171, 171, 171, 0.75)",
    filter: "blur(8px)"
  },
  colorPrimary: {
    color: primaryColor[0]
  }
};

export default loaderStyle;