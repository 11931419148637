const SociographicService = {
  getArrays(dataArray) {
    const maleFemaleArray = dataArray.slice(0, 2);
    const maleArray = dataArray.slice(2, 19);
    const femaleArray = dataArray.slice(19, 36);
    const houseHoldsByAge = dataArray.slice(36, 50);
    const houseHoldsWIthChildren = dataArray.slice(50, 58);
    const houseHoldsNetIncome = dataArray.slice(58, 64);
    const houseHoldsWithTitle = dataArray.slice(64, 65);
    const houseHoldsHead = dataArray.slice(65, 66);
    const purchusingPowers = dataArray.slice(66, 75);
    const inventory = dataArray.slice(75, 78);
    const generalMaintance = dataArray.slice(78, 84);
    const houseLocation = dataArray.slice(84, 86);
    const houseStreetType = dataArray.slice(86, 92);
    const residentialArea = dataArray.slice(92, 93);

    return {
      maleFemaleArray,
      maleArray,
      femaleArray,
      houseHoldsByAge,
      houseHoldsWIthChildren,
      houseHoldsNetIncome,
      houseHoldsWithTitle,
      houseHoldsHead,
      purchusingPowers,
      inventory,
      generalMaintance,
      houseLocation,
      houseStreetType,
      residentialArea
    };
  },
  filterGender(dataArray, gender, male, female, age) {
    const { maleArray, femaleArray } = this.getArrays(dataArray);
    let result = [];

    switch (gender) {
      case "male":
        result = maleArray;
        break;
      case "female":
        result = femaleArray;
        break;
      default:
        result = [];
    }

    if (age === "") {
      return result;
    }
    if (
      age !== "" &&
      (gender === "male" || gender === "female" || gender === "all")
    ) {
      result = result.filter(item => item[0].substr(1) === age);
    }
    return result;
  },
  filterHouseHoldsByAge(dataArray, hHByAge) {
    const { houseHoldsByAge } = this.getArrays(dataArray);
    let result = [];

    if (hHByAge === "all") {
      return houseHoldsByAge;
    }
    result = houseHoldsByAge.filter(h => h[0] === hHByAge);

    if (hHByAge === "age_of_hh") {
      result = houseHoldsByAge;
    }
    return result;
  },
  filterHouseHoldsWithNetIncome(dataArray, hHWithNetIncome) {
    const { houseHoldsNetIncome } = this.getArrays(dataArray);
    return houseHoldsNetIncome;
  },
  filterMaintenceStatus(dataArray) {
    const { generalMaintance } = this.getArrays(dataArray);
    return generalMaintance;
  },
  splitChartData(array, type) {
    let label = [];
    let series = [];
    let chartData = {};
    if (array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        if (type) {
          if (type === "status") {
            label.push(array[i][0].substring(42));
            series.push(array[i][1]);
          }
          if (type === "income") {
            label.push(array[i][0].substring(40));
            series.push(array[i][1]);
          }
        } else {
          label.push(array[i][0]);
          series.push(array[i][1]);
        }
      }
      chartData = { labels: label, series: [series] };
    }
    return chartData;
  },
  gendnerItems: [
    { id: 2, name: "Male", value: "male" },
    { id: 3, name: "Female", value: "female" }
  ]
};

export default SociographicService;
