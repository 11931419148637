import { grayColor } from "../../../material-dashboard-react";

const dashboardStyle = theme =>  ({
  featuresWrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  plzColorPrimary: {
    backgroundColor: "lightpink"
  },
  plzBarColorPrimary: {
    backgroundColor: "crimson"
  },
  countryColorPrimary: {
    backgroundColor: "moccasin"
  },
  countryBarColorPrimary: {
    backgroundColor: "yellow"
  },
  cityColorPrimary: {
    backgroundColor: "palegreen"
  },
  cityBarColorPrimary: {
    backgroundColor: "lightseagreen"
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  progressHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      margin: 0
    }
  },
  searchResult: {
    position: "relative"
  },
  splitResultsContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column"
  },
  infoText: {
    textAlign: "justify"
  }
});

export default dashboardStyle;
