const arrowStyle = {
  arrow: {
    position: "absolute",
    zIndex: 100,
    bottom: "30px",
    backgroundColor: "white",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex"
  },
  opened: {
    left: "248px"
  },
  closed: {
    left: "53px"
  }
};

export default arrowStyle;
