import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import create from "react-form-validation-hoc";

import styles from "../../assets/jss/material-dashboard-react/components/search/formStyle";

// core components
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import { required } from "../../config/validator-rules";
import CustomInput from "../CustomInput/CustomInput";
import FeatureSearchService from "../../services/FeatureSearchService";

const useStyles = makeStyles(styles);

const Form = props => {
  const classes = useStyles();
  const [isFormFilled, setFormFilled] = useState(false);

  const { FieldDecorator, errors } = props.validator;

  const {
    onChangeSearchParam,
    onSubmit,
    isLoadding,
    suggestions,
    onSuggestionClick,
    isSuggestionsModal,
    objectName
  } = props;

  const { featuresSearch, index } = props.params;

  useEffect(() => {
    let values = [];
    featuresSearch.forEach((f, i) => {
      let keys = Object.keys(featuresSearch[i]);
      keys.forEach(k => {
        if (k !== "lat" && k !== "lng" && k !== "isTyping") values.push(f[k]);
      });
      if (FeatureSearchService.arrayChecker(values)) {
        setFormFilled(true);
      } else {
        setFormFilled(false);
      }
    });
  });

  let isFormValid = false;
  isFormValid =
    !!errors.city ||
    !!errors.zip ||
    !!errors.streetname ||
    !!errors.housenumber ||
    !isFormFilled
      ? false
      : true;

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.splitFormsContainer}>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{objectName}</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <FieldDecorator
                name={"streetname"}
                value={featuresSearch[index].streetname}
                rules={[required("streetname")]}
              />
              <CustomInput
                labelText="Streetname"
                id={`streetname_${index}`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  onChange: event => {
                    onChangeSearchParam(
                      "streetname",
                      event.target.value,
                      index
                    );
                  },
                  value: featuresSearch[index].streetname
                }}
                message={{ error: errors.streetname }}
                suggestions={suggestions.streetname}
                onSuggestionClick={value =>
                  onSuggestionClick("streetname", value, index)
                }
                isSuggestionsModal={isSuggestionsModal}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <FieldDecorator
                name={"zip"}
                value={featuresSearch[index].zip}
                rules={[required("zip")]}
              />
              <CustomInput
                labelText="Zip"
                id={`zip_${index}`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  onChange: event =>
                    onChangeSearchParam("zip", event.target.value, index),
                  value: featuresSearch[index].zip
                }}
                message={{ error: errors.zip }}
                suggestions={suggestions.zip}
                onSuggestionClick={value =>
                  onSuggestionClick("zip", value, index)
                }
                isSuggestionsModal={isSuggestionsModal}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <FieldDecorator
                name={"city"}
                value={featuresSearch[index].city}
                rules={[required("city")]}
              />
              <CustomInput
                labelText="City"
                id={`city_${index}`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  onChange: event =>
                    onChangeSearchParam("city", event.target.value, index),
                  value: featuresSearch[index].city
                }}
                message={{ error: errors.city }}
                suggestions={suggestions.city}
                onSuggestionClick={value =>
                  onSuggestionClick("city", value, index)
                }
                isSuggestionsModal={isSuggestionsModal}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <FieldDecorator
                name={"housenumber"}
                value={featuresSearch[index].housenumber}
                rules={[required("housenumber")]}
              />
              <CustomInput
                labelText="House Number"
                id={`housenumber_${index}`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  onChange: event =>
                    onChangeSearchParam(
                      "housenumber",
                      event.target.value,
                      index
                    ),
                  value: featuresSearch[index].housenumber
                }}
                message={{ error: errors.housenumber }}
              />
            </GridItem>
          </GridContainer>
          {featuresSearch.length <= 1 && (
            <GridItem xs={12} sm={12} md={6}>
              <Button
                onClick={() => onSubmit(index)}
                color={"primary"}
                round
                disabled={isLoadding || !isFormValid}
              >
                Suchen
              </Button>
            </GridItem>
          )}
          {featuresSearch.length >= 1 && index === 1 && (
            <GridItem xs={12} sm={12} md={6}>
              <Button
                onClick={() => onSubmit(index)}
                color={"primary"}
                round
                disabled={isLoadding || !isFormValid}
              >
                Suchen
              </Button>
            </GridItem>
          )}
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default create()(Form);
