const selectedChart = {
  chartModal: {
    justifyContent: "center",
    alignItems: "center",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "352px",
    bottom: "50%",
    margin: "0 auto",
    zIndex: 20,
    position: "absolute",
    transform: "translate(-50%, -50%)",
    boxSizing: "border-box",
    maxHeight: "100%",
    backgroundColor: "white"
  },
  chartModalOpened: {
    display: "flex"
  },
  chartModalClosed: {
    display: "none"
  },
  closeIcon: {
    position: "absolute",
    top: "0",
    right: "0",
    cursor: "pointer",
    color: "black",
    height: "20px",
    width: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid",
    borderRadius: "50%"
  }
};

export default selectedChart;
