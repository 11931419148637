import React, { useCallback } from "react";
import { connect } from "react-redux";
import * as VarianceAPI from "../../api/Variance";
import * as actionCreator from "../../store/actions";
import GrowthRateTable from "./TypeGrowthRateTable";

const TypeGrowthRate = props => {
  const { title, color, value } = props;
  const content = [];

  const sendRequest = useCallback(
    (page, rowsPerPage, title, typeName, params) => {
      return VarianceAPI.zipcodeGrowthRate(
        page + 1,
        rowsPerPage,
        title,
        typeName,
        params
      );
    },
    // eslint-disable-next-line
    [title]
  );

  return (
    <GrowthRateTable
      methods={{
        sendRequest
      }}
      items={props[value]}
      table={{
        tableHead: ["#", "Zipcode", "Rate"],
        tableData: content
      }}
      title={title}
      color={color}
      value={value}
    />
  );
};

const mapStateToProps = state => {
  const { variance } = state;
  return {
    saleGrowth: variance.saleGrowth,
    rentGrowth: variance.rentGrowth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    set: value => dispatch(actionCreator.save(value)),
    toggleLoadding: (property, value) =>
      dispatch(actionCreator.toggleLoadding(property, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TypeGrowthRate);
