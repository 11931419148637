import * as actionTypes from "./actionTypes";

export const set = (property, value) => {
  return {
    type: actionTypes.RESULTS_SET,
    payload: {
      property,
      value
    }
  };
};

export const setMultiple = value => {
  return {
    type: actionTypes.RESULTS_MULTIPLE,
    payload: {
      value
    }
  };
};

export const reset = () => {
  return {
    type: actionTypes.RESET_RESULTS
  };
};

export const setSearchResults = (property, value, index) => {
  return {
    type: actionTypes.SET_SEARCH_RESULTS,
    payload: {
      property,
      value,
      index
    }
  };
};
