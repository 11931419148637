import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import PropTypes from "prop-types";

import { places } from "../../config/google";

import Suggestions from "../Search/Suggestions";
import CustomInput from "../CustomInput/CustomInput";

const LocationSearchInput = props => {
  const { address, onChange, onSelect, message } = props;

  return (
    <PlacesAutocomplete
      value={address}
      onChange={onChange}
      onSelect={onSelect}
      searchOptions={places}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: "relative" }}>
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            labelText="Adresse/Standort"
            inputProps={{
              ...getInputProps()
            }}
            message={{ error: message }}
          />
          {suggestions.length > 0 ? (
            <Suggestions
              getSuggestionItemProps={getSuggestionItemProps}
              loading={loading}
              suggestions={suggestions}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;

LocationSearchInput.prototype = {
  address: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func
};
