import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionCreator from "../../store/actions";
import ProductService from "../../services/ProductService";
import ProductApi from "../../api/Product";
import { MenuItem } from "@material-ui/core";

import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Chart from "./Chart";
import CustomSelect from "../CustomSelect/CustomSelect";
import SelectedChart from "./SelectedChart";

class ChartList extends Component {
  state = {
    category: ""
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (
  //     this.state.category !== nextState.category &&
  //     this.props.separateValue !== nextProps.separateValue
  //   ) {
  //     return true;
  //   }
  //   return !(
  //     this.props.graphicsData.sale.length > 0 &&
  //     this.props.graphicsData.sale.length === nextProps.graphicsData.sale.length
  //   );
  // }

  componentDidMount() {
    this.props.toggleLoading("chartList", true);
    const category =
      this.props.category === "Alle"
        ? this.state.category
        : this.props.category;

    ProductApi.getGraphicsByMonth(
      this.props.lat,
      this.props.lng,
      this.props.address,
      category,
      this.props.zipcode,
      this.props.separateValue
    ).then(resp => {
      const data = resp.data.data;
      const splittedGeneralData = ProductService.splitDataByType(data.general);
      const splittedBelowData = ProductService.splitDataByType(data.below);
      const splittedAboveData = ProductService.splitDataByType(data.above);

      const sale = splittedGeneralData.sale;
      const rent = splittedGeneralData.rent;

      const saleAbove = splittedAboveData.sale;
      const rentAbove = splittedAboveData.rent;

      const saleBelow = splittedBelowData.sale;
      const rentBelow = splittedBelowData.rent;

      this.setState({
        category: this.props.category
      });
      this.props.setMultiple({
        graphicsData: {
          sale,
          rent,
          saleBelow,
          rentBelow,
          saleAbove,
          rentAbove
        }
      });

      this.props.toggleLoading("chartList", false);
    });
  }

  onChangeCategory = event => {
    this.setState({
      category: event.target.value
    });
  };

  onChartClick = (type, color, data, title, subtitle) => {
    this.props.setParams("selectedChartParams", {
      type,
      color,
      data,
      title,
      subtitle
    });
    this.props.toggleModals({
      isSelectedChart: true
    });
  };

  onChartClose = () => {
    this.props.toggleModals({
      isSelectedChart: false
    });
    this.props.setParams("selectedChartParams", {});
  };

  render() {
    const sale = ProductService.sortData(
      this.props.graphicsData.sale,
      this.state.category
    );

    const rent = ProductService.sortData(
      this.props.graphicsData.rent,
      this.state.category
    );

    const saleAvgTimeOnline = ProductService.getAvgOnlineTime(
      this.props.graphicsData.sale
    );
    const rentAvgTimeOnline = ProductService.getAvgOnlineTime(
      this.props.graphicsData.rent
    );

    const saleData = ProductService.generateChartData(
      sale,
      this.props.graphicsData.saleAbove,
      this.props.graphicsData.saleBelow,
      this.state.category
    );
    const rentData = ProductService.generateChartData(
      rent,
      this.props.graphicsData.rentAbove,
      this.props.graphicsData.rentBelow,
      this.state.category
    );

    const saleGrowthRateData = ProductService.calculateGrowthData(sale);
    const rentGrowthRateData = ProductService.calculateGrowthData(rent);

    const data = {
      sale: {
        labels: saleData.dataMonths,
        series: [saleData.dataPrices]
      },
      saleAbove: {
        labels: saleData.dataAboveMonths,
        series: [saleData.dataAbovePrices]
      },
      saleBelow: {
        labels: saleData.dataBelowMonths,
        series: [saleData.dataBelowPrices]
      },
      saleGrowthRateData: {
        labels: Object.keys(saleGrowthRateData),
        series: [Object.values(saleGrowthRateData)]
      },
      rent: {
        labels: rentData.dataMonths,
        series: [rentData.dataPrices]
      },
      rentBelow: {
        labels: rentData.dataBelowMonths,
        series: [rentData.dataBelowPrices]
      },
      rentAbove: {
        labels: rentData.dataAboveMonths,
        series: [rentData.dataAbovePrices]
      },
      rentGrowthRateData: {
        labels: Object.keys(rentGrowthRateData),
        series: [Object.values(rentGrowthRateData)]
      },
      saleAvgTimeOnline: {
        labels: Object.keys(saleAvgTimeOnline),
        series: [Object.values(saleAvgTimeOnline)]
      },
      rentAvgTimeOnline: {
        labels: Object.keys(rentAvgTimeOnline),
        series: [Object.values(rentAvgTimeOnline)]
      }
    };

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h3>Graphen reduzieren</h3>
        </GridItem>
        {this.props.category === "Alle" ? (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer>
              <GridItem md={3}>
                <CustomSelect
                  labelText="Category"
                  id="category"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: this.onChangeCategory,
                    value: this.state.category
                  }}
                >
                  <MenuItem key={"1"} value={"Alle"}>
                    Alle
                  </MenuItem>
                  {this.props.categories.length > 0 &&
                    this.props.categories.map((category, index) => (
                      <MenuItem
                        key={`category-${index}`}
                        value={category.type_name}
                      >
                        {category.type_name}
                      </MenuItem>
                    ))}
                </CustomSelect>
              </GridItem>
            </GridContainer>
          </GridItem>
        ) : (
          ""
        )}
        {this.props.isSelectedChart && (
          <SelectedChart
            onChartClose={this.onChartClose}
            isChartOpened={this.props.isSelectedChart}
            isSidebarToggled={this.props.isSidebarToggled}
            data={data.sale}
            params={this.props.selectedChartParams}
          />
        )}

        <GridItem xs={12} sm={4} md={4} lg={4}>
          <Chart
            type={"Line"}
            color={"danger"}
            data={data.sale}
            title={"Kaufen €"}
            subtitle={"Average value"}
            onChartClick={() =>
              this.onChartClick(
                "Line",
                "danger",
                data.sale,
                "Kaufen €",
                "Average value"
              )
            }
          />
        </GridItem>
        {parseInt(this.props.separateValue) !== 0 &&
        this.props.category === "Wohnung" ? (
          <GridItem xs={12} sm={4} md={4} lg={4}>
            <Chart
              type={"Line"}
              color={"warning"}
              data={data.saleAbove}
              title={"Kaufen €"}
              subtitle={`Average value for > ${this.props.separateValue}m²`}
              onChartClick={() =>
                this.onChartClick(
                  "Line",
                  "warning",
                  data.saleAbove,
                  "Kaufen €",
                  `Average value for > ${this.props.separateValue}m²`
                )
              }
            />
          </GridItem>
        ) : (
          ""
        )}
        {parseInt(this.props.separateValue) !== 0 &&
        this.props.category === "Wohnung" ? (
          <GridItem xs={12} sm={4} md={4} lg={4}>
            <Chart
              type={"Line"}
              color={"success"}
              data={data.saleBelow}
              title={"Kaufen €"}
              subtitle={`Average value for < ${this.props.separateValue}m²`}
              onChartClick={() =>
                this.onChartClick(
                  "Line",
                  "success",
                  data.saleBelow,
                  "Kaufen €",
                  `Average value for < ${this.props.separateValue}m²`
                )
              }
            />
          </GridItem>
        ) : (
          ""
        )}
        {this.props.category !== "Grundstücke" ? (
          <GridItem xs={12} sm={4} md={4} lg={4}>
            <Chart
              type={"Line"}
              color={"danger"}
              data={data.rent}
              title={"Mieten €"}
              subtitle={"Average value"}
              onChartClick={() =>
                this.onChartClick(
                  "Line",
                  "danger",
                  data.rent,
                  "Mieten €",
                  "Average value"
                )
              }
            />
          </GridItem>
        ) : (
          ""
        )}
        {parseInt(this.props.separateValue) !== 0 &&
        this.props.category === "Wohnung" &&
        this.props.category !== "Grundstücke" ? (
          <GridItem xs={12} sm={4} md={4} lg={4}>
            <Chart
              type={"Line"}
              color={"warning"}
              data={data.rentAbove}
              title={"Mieten €"}
              subtitle={`Average value for > ${this.props.separateValue}m²`}
              onChartClick={() =>
                this.onChartClick(
                  "Line",
                  "warning",
                  data.rentAbove,
                  "Mieten €",
                  `Average value for > ${this.props.separateValue}m²`
                )
              }
            />
          </GridItem>
        ) : (
          ""
        )}
        {parseInt(this.props.separateValue) !== 0 &&
        this.props.category !== "Grundstücke" &&
        this.props.category === "Wohnung" ? (
          <GridItem xs={12} sm={4} md={4} lg={4}>
            <Chart
              type={"Line"}
              color={"success"}
              data={data.rentBelow}
              title={"Mieten €"}
              subtitle={`Average value for < ${this.props.separateValue}m²`}
              onChartClick={() =>
                this.onChartClick(
                  "Line",
                  "success",
                  data.rentBelow,
                  "Mieten €",
                  `Average value for < ${this.props.separateValue}m²`
                )
              }
            />
          </GridItem>
        ) : (
          ""
        )}
        {data.saleGrowthRateData.series ? (
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Chart
              type={"Line"}
              color={"info"}
              data={data.saleGrowthRateData}
              title={"Kaufen %"}
              subtitle={"Growth rate"}
              onChartClick={() =>
                this.onChartClick(
                  "Line",
                  "info",
                  data.saleGrowthRateData,
                  "Kaufen %",
                  "Growth rate"
                )
              }
            />
          </GridItem>
        ) : (
          ""
        )}
        {data.rentGrowthRateData.series &&
        this.props.category !== "Grundstücke" ? (
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Chart
              type={"Line"}
              color={"info"}
              data={data.rentGrowthRateData}
              title={"Mieten %"}
              subtitle={"Growth rate"}
              onChartClick={() =>
                this.onChartClick(
                  "Line",
                  "info",
                  data.rentGrowthRateData,
                  "Mieten %",
                  "Growth rate"
                )
              }
            />
          </GridItem>
        ) : (
          ""
        )}
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Chart
            type={"Bar"}
            color={"success"}
            data={data.saleAvgTimeOnline}
            title={"Kaufen"}
            subtitle={"Properties average duration of being active"}
            onChartClick={() =>
              this.onChartClick(
                "Bar",
                "success",
                data.saleAvgTimeOnline,
                "Kaufen",
                "Properties average duration of being active"
              )
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Chart
            type={"Bar"}
            color={"success"}
            data={data.rentAvgTimeOnline}
            title={"Mieten"}
            subtitle={"Properties average duration of being active"}
            onChartClick={() =>
              this.onChartClick(
                "Bar",
                "success",
                data.rentAvgTimeOnline,
                "Mieten",
                "Properties average duration of being active"
              )
            }
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    category: state.search.category,
    categories: state.search.categories,
    sale: state.results.separatedData.sale,
    saleBelow: state.results.separatedData.saleBelow,
    saleAbove: state.results.separatedData.saleAbove,
    rent: state.results.separatedData.rent,
    rentBelow: state.results.separatedData.rentBelow,
    rentAbove: state.results.separatedData.rentAbove,
    lat: state.search.lat,
    lng: state.search.lng,
    radius: state.search.radius,
    address: state.search.address,
    zipcode: state.search.zipcode,
    separateValue: state.search.separateValue,
    avgSalePrice: state.results.avgSale,
    avgRentPrice: state.results.avgRent,
    graphicsData: {
      sale: state.results.graphicsData.sale,
      rent: state.results.graphicsData.rent,
      saleAbove: state.results.graphicsData.saleAbove,
      saleBelow: state.results.graphicsData.saleBelow,
      rentAbove: state.results.graphicsData.rentAbove,
      rentBelow: state.results.graphicsData.rentBelow,
      saleAvgTimeOnline: state.results.graphicsData.saleAvgTimeOnline,
      rentAvgTimeOnline: state.results.graphicsData.rentAvgTimeOnline
    },
    isSelectedChart: state.ui.isSelectedChart,
    isSidebarToggled: state.ui.isSidebarToggled,
    selectedChartParams: state.results.selectedChartParams,
    chartsCategory: state.search.chartsCategory
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMultiple: value => dispatch(actionCreator.setMultiple(value)),
    changeSearchParam: (property, value) =>
      dispatch(actionCreator.changeSearchParam(property, value)),
    toggleLoading: (property, value) =>
      dispatch(actionCreator.toggleLoadding(property, value)),
    setParams: (property, value) =>
      dispatch(actionCreator.set(property, value)),
    changeSeachParam: (property, value) =>
      dispatch(actionCreator.changeSearchParam(property, value)),
    toggleModals: value => dispatch(actionCreator.toggleModals(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartList);
