const FeatureSearchService = {
  formatFeatureName(str) {
    const capitalized = str[0].toUpperCase() + str.slice(1);
    return capitalized.replace(/_/g, " ");
  },
  arrayChecker(arr) {
    return arr.every(Boolean);
  },
  checkNegativeValues(value) {
    return value < 0 ? 0 : value;
  },
  initStates: {
    featuresSearchInit: {
      lat: "",
      lng: "",
      city: "",
      zip: "",
      streetname: "",
      housenumber: "",
      objectName: "Object B",
      isTyping: false
    },
    loadersInit: {
      featuresLoading: ""
    },
    resultsInit: {
      socialStatuses: {},
      typeSuggestions: [],
      featureMarkers: [],
      rectangleMarkers: []
    }
  }
};

export default FeatureSearchService;
