import React from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import Card from "../../components/Card/Card";
import CardBody from "../Card/CardBody";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CardFooter from "../Card/CardFooter";

const useStyles = makeStyles(styles);

const CardTool = props => {
  const classes = useStyles();
  const { title, value } = props;

  return (
    <Card
      style={{
        marginTop: 0
      }}
    >
      <CardBody>
        <h3 className={classes.cardTitle}>{title}</h3>
      </CardBody>
      <CardFooter>
        <div className={classes.stats}>
          <p>{value}</p>
        </div>
      </CardFooter>
    </Card>
  );
};

export default CardTool;
