import React, { Component } from "react";
import { connect } from "react-redux";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import * as actionCreator from "../../store/actions/index";

import Aux from "../../hoc/AuxReact";

// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";

import Form from "../../components/FeatureSearch/Form";
import ResultsContainer from "../../components/FeatureSearch/ResultsContainer";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/components/feature-search/features";
import FeatureSearchApi from "../../api/FeatureSearch";
import FeatureSearchService from "../../services/FeatureSearchService";

class FeatureSearch extends Component {
  constructor() {
    super();
    this.state = {
      suggestionsObject: {}
    };
  }

  handleCheckboxes = e => {
    const name = e.target.name;
    const isChecked = e.target.checked;
    const { checkBoxes } = this.props;
    checkBoxes.forEach(chb => {
      if (chb.name === name) chb.isChecked = isChecked;
    });
    this.props.setFeaturesSearchParam("checkBoxes", checkBoxes);
  };

  handleScreenSplit = e => {
    const {
      featuresSearchInit,
      loadersInit,
      resultsInit
    } = FeatureSearchService.initStates;
    let featuresSearch = this.props.featuresSearch;
    let loaders = this.props.loaders;
    let results = this.props.results;

    if (e.target.checked) {
      featuresSearch.push(featuresSearchInit);
      loaders.push(loadersInit);
      results.push(resultsInit);
    } else {
      featuresSearch.pop();
      loaders.pop();
      results.pop();
    }
    this.props.setFeaturesSearchParam(
      "isFetureSearchSplitted",
      e.target.checked
    ); //split screen checkbox
    this.props.setFeaturesSearchParam("featuresSearch", featuresSearch);
    this.props.toggleLoadings(loaders);
  };

  handleAllChecked = e => {
    const { checkBoxes } = this.props;
    checkBoxes.forEach(chb => (chb.isChecked = e.target.checked));
    this.props.setFeaturesSearchParam("checkBoxes", checkBoxes);
  };

  onParamChange = (type, value, index) => {
    const { city, zip } = this.props.featuresSearch[index];
    this.props.setFeaturesSearchNestedParam(type, value, index);
    if (type !== "housenumber") {
      this.props.setFeaturesSearchNestedParam("isTyping", true, index);
    }
    this.props.setFeaturesSearchResults("typeSuggestions", [], index);

    if (value.length > 0 && type !== "housenumber") {
      FeatureSearchApi.getSuggestions({
        type,
        value,
        city,
        zip
      })
        .then(resp => {
          const data = resp.data.data;
          const suggestions = { [type]: [] };

          data.forEach(d => {
            if (type === "streetname") {
              suggestions[type].push(d.streetname);
            }
            if (type === "zip") {
              suggestions[type].push(d.zip);
            }
            if (type === "city") {
              suggestions[type].push(d.city);
            }
          });
          if (data.length === 0) {
            this.props.setFeaturesSearchResults("typeSuggestions", [], index);
          }

          this.props.setFeaturesSearchResults(
            "typeSuggestions",
            suggestions,
            index
          );

          this.setState({ suggestionsObject: data });
        })
        .catch(err => console.error(err));
    }
  };

  onSuggestionClick = (type, suggestion, index) => {
    const { suggestionsObject } = this.state;

    if (type === "streetname") {
      suggestionsObject.forEach(sug => {
        if (sug.streetname === suggestion) {
          this.props.setFeaturesSearchNestedParam("zip", sug.zip, index);
          this.props.setFeaturesSearchNestedParam("city", sug.city, index);
        }
      });
    }
    if (type === "zip") {
      suggestionsObject.forEach(sug => {
        if (sug.zip === suggestion) {
          this.props.setFeaturesSearchNestedParam("city", sug.city, index);
        }
      });
    }
    this.props.setFeaturesSearchNestedParam(type, suggestion, index);
    this.props.setFeaturesSearchNestedParam("isTyping", false, index);

    this.props.setFeaturesSearchResults("typeSuggestions", [], index);
  };

  onSubmitHandler = index => {
    const { featuresSearch } = this.props;
    this.props.setFeaturesSearchResults("typeSuggestions", [], index);
    let responseError = [];

    for (let index = 0; index < featuresSearch.length; index++) {
      this.props.toggleDynamicLoaders("featuresLoading", true, index);

      FeatureSearchApi.getFeaturesStatuses(featuresSearch[index])
        .then(res => {
          responseError[index] = null;
          this.props.setFeaturesSearchParam("responseError", responseError);
          if (res === true) {
            this.props.toggleDynamicLoaders("featuresLoading", false, index);
            responseError[
              index
            ] = `No results found for ${featuresSearch[index].city}!`;
            this.props.setFeaturesSearchParam("responseError", responseError);
          }
          return res;
        })
        .then(res => {
          const data = res.data.data;
          responseError[index] = "";
          this.props.setFeaturesSearchParam("responseError", responseError);
          let centerLat =
            (data.rectangle[1].product_latitude +
              data.rectangle[3].product_latitude) /
            2;
          let centerLng =
            (data.rectangle[1].product_longitude +
              data.rectangle[3].product_longitude) /
            2;
          this.props.setFeaturesSearchNestedParam("lat", centerLat, index);
          this.props.setFeaturesSearchNestedParam("lng", centerLng, index);
          this.props.setFeaturesSearchResults(
            "featureMarkers",
            data.markers,
            index
          );
          this.props.setFeaturesSearchResults(
            "rectangleMarkers",
            data.rectangle,
            index
          );
          let chb = [];
          Object.keys(data.features).forEach(s => {
            chb.push({ name: s, isChecked: true });
          });
          this.props.setFeaturesSearchParam("checkBoxes", chb);
          this.props.setFeaturesSearchResults(
            "socialStatuses",
            data.features,
            index
          );
          this.props.toggleDynamicLoaders("featuresLoading", false, index);
        })
        .catch(error => console.log(error));
    }
    this.props.setFeaturesSearchParam("responseError", responseError);
  };

  render() {
    const {
      results,
      loaders,
      featuresSearch,
      classes,
      checkBoxes,
      responseError,
      isFetureSearchSplitted
    } = this.props;

    return (
      <Aux>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.splitResultsContainer}
            >
              {featuresSearch.map((f, i) => {
                return (
                  <Form
                    onSubmit={this.onSubmitHandler}
                    onChangeSearchParam={this.onParamChange}
                    isLoadding={loaders[i].featuresLoading}
                    params={{ featuresSearch: featuresSearch, index: i }}
                    suggestions={results[i].typeSuggestions}
                    onSuggestionClick={this.onSuggestionClick}
                    isSuggestionsModal={featuresSearch[i].isTyping}
                    objectName={f.objectName}
                    key={i}
                  />
                );
              })}
            </GridItem>
            <GridItem xs={12} sm={12} md={9} lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFetureSearchSplitted}
                    onChange={this.handleScreenSplit}
                    name="isFetureSearchSplitted"
                    color="primary"
                  />
                }
                label="Split Screen"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <ResultsContainer
                loaders={loaders}
                results={results}
                responseError={responseError}
                featuresSearch={featuresSearch}
                checkBoxes={checkBoxes}
                handleAllChecked={this.handleAllChecked}
                handleCheckboxes={this.handleCheckboxes}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  const { ui, featuresSearch } = state;

  return {
    searchFormParams: {
      lat: featuresSearch.lat,
      lng: featuresSearch.lng,
      city: featuresSearch.city,
      zip: featuresSearch.zip,
      streetname: featuresSearch.streetname,
      housenumber: featuresSearch.housenumber
    },
    results: featuresSearch.featuresSearchResults,
    loaders: ui.loaders,
    featuresSearch: featuresSearch.featuresSearch,
    checkBoxes: featuresSearch.checkBoxes,
    responseError: featuresSearch.responseError,
    isFetureSearchSplitted: featuresSearch.isFetureSearchSplitted
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleLoadings: value => dispatch(actionCreator.toggleMultiple(value)),
    toggleDynamicLoaders: (property, value, index) =>
      dispatch(actionCreator.toggleDynamicLoaders(property, value, index)),
    setFeaturesSearchParam: (property, value) =>
      dispatch(actionCreator.setFeaturesSearchParam(property, value)),
    setFeaturesSearchNestedParam: (property, value, index) =>
      dispatch(
        actionCreator.setFeaturesSearchNestedParam(property, value, index)
      ),
    setFeaturesSearchResults: (property, value, index) =>
      dispatch(actionCreator.setFeaturesSearchResults(property, value, index))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FeatureSearch));
