import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import { Switch, FormControlLabel } from "@material-ui/core";
import { purple } from "@material-ui/core/colors";

const PurpleSwitch = withStyles({
  switchBase: {
    color: purple[300],
    "&$checked": {
      color: purple[500]
    },
    "&$checked + $track": {
      backgroundColor: purple[500]
    }
  },
  checked: {},
  track: {},
  root: {
    transform: "scale(0.8)"
  }
})(Switch);

const styles = {
  label: {
    color: "#000"
  }
};

const useStyles = makeStyles(styles);

const CustomizedSwitches = ({ title, isChecked, onSwitch }) => {
  const [state, setState] = React.useState({
    checkedA: true
  });
  const classes = useStyles();

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
    onSwitch(title);
  };

  return (
    <FormControlLabel
      classes={{
        label: classes.label
      }}
      control={
        <PurpleSwitch
          checked={isChecked}
          onChange={handleChange("checkedA")}
          value="checkedA"
        />
      }
      label={title}
    />
  );
};

export default connect()(CustomizedSwitches);
