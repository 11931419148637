import React, { Component } from "react";
import { connect } from "react-redux";

import Map from "../../ui/Map/Map";

class FeatureSearchMap extends Component {
  getMarkers = bounds => {};

  render() {
    const { results, index } = this.props;
    return (
      <Map
        markers={results[index].featureMarkers}
        center={this.props.center}
        radius={this.props.radius}
        polyline={results[index].rectangleMarkers}
        typeIds={this.props.typeIds}
        onIdle={this.getMarkers}
        myZoom={16}
        isInfoWindow={true}
      />
    );
  }
}

const mapStateToProps = state => {
  const { featuresSearch, search } = state;

  return {
    results: featuresSearch.featuresSearchResults,
    radius: search.radius
  };
};

export default connect(
  mapStateToProps,
  null
)(FeatureSearchMap);
