import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../unitily";

const initState = {
  loading: false,
  statistic: false,
  list: false,
  nearByInfo: false,
  chartList: false,
  topGrowth: false,
  saleGrowth: false,
  rentGrowth: false,
  openModal: false,
  isSidebarToggled: false,
  socLoading: false,
  isSelectedChart: false,
  isModalOpened: false,
  loaders: [{ featuresLoading: "" }]
};

const toggleLoader = (state, action) => {
  const updState = { ...state };
  updState[action.payload.property] = action.payload.value;
  return updateObject(state, {
    ...updState
  });
};

const toggleMultiple = (state, action) => {
  return updateObject(state, {
    ...action.payload.value
  });
};

const toggleModals = (state, action) => {
  return updateObject(state, {
    ...action.payload.value
  });
};

const toggleDynamicLoaders = (state, action) => {
  const { property, value, index } = action.payload;
  const updState = { ...state };
  updState.loaders[index][property] = value;

  return updateObject(state, {
    ...updState
  });
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return toggleLoader(state, action);
    case actionTypes.LOADING_MULTIPLE:
      return toggleMultiple(state, action);
    case actionTypes.TOGGLE_SIDEBAR:
      return { ...state, isSidebarToggled: !state.isSidebarToggled };
    case actionTypes.TOGGLE_MODALS:
      return toggleModals(state, action);
    case actionTypes.TOGGLE_DYNAMIC_LOADERS:
      return toggleDynamicLoaders(state, action);
    default:
      return state;
  }
};

export default reducer;
