// LIBRARIES
import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

// COMPONENTS
import Loader from "../../../ui/Loader/Loader";
import NewsAndStatista from "../../Search/NewsAndStatista";

import { MenuItem } from "@material-ui/core";

import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import SociographicService from "../../../services/SociographicService";
import styles from "../../../assets/jss/material-dashboard-react/components/search/tabs/sociographicStyle";
import CustomSelect from "../../CustomSelect/CustomSelect";
import Chart from "../../Chart/Chart";
import SelectedChart from "../../Chart/SelectedChart";
import * as actionCreator from "../../../store/actions";

class Sociographic extends Component {
  constructor() {
    super();
    this.state = {
      chartData: {},
      gender: "male",
      age: "",
      hHByAge: "age_of_hh",
      hHAge: ""
    };
  }

  componentDidUpdate(prevProps) {
    const { randomText } = this.props;
    if (randomText !== prevProps.randomText) {
      this.setState({ chartData: this.props.results.sociographic });
    }
  }

  onChartClick = (type, color, data, title, subtitle) => {
    this.props.setParams("selectedChartParams", {
      type,
      color,
      data,
      title,
      subtitle
    });
    this.props.toggleModals({
      isSelectedChart: true
    });
  };

  onChartClose = () => {
    this.props.toggleModals({
      isSelectedChart: false
    });
    this.props.setParams("selectedChartParams", {});
  };

  render() {
    const { classes } = this.props;
    const { gender, age, hHByAge, hHAge } = this.state;
    const data = this.props.results.sociographic;

    const { male, female } = data;
    const dataArray = Object.entries(data);

    const filteredGender = SociographicService.filterGender(
      dataArray,
      gender,
      male,
      female,
      age
    );

    const filteredHouseHoldsByAgeData = SociographicService.filterHouseHoldsByAge(
      dataArray,
      hHByAge,
      hHAge
    );
    const filteredHouseHoldsWithNetData = SociographicService.filterHouseHoldsWithNetIncome(
      dataArray
    );
    const filteredMaintenceStatusData = SociographicService.filterMaintenceStatus(
      dataArray
    );
    //gender chart data
    const genderChartData = SociographicService.splitChartData(filteredGender);
    //maintence chart data
    const maintenceChartData = SociographicService.splitChartData(
      filteredMaintenceStatusData,
      "status"
    );
    //income chart data
    const withIncomeChartData = SociographicService.splitChartData(
      filteredHouseHoldsWithNetData,
      "income"
    );
    //house holds by age chart data
    const houseHoldsByAgeChartData = SociographicService.splitChartData(
      filteredHouseHoldsByAgeData
    );

    return (
      <GridContainer className={classes.root}>
        {/*selects for filtered male or female start*/}
        {/* {Object.keys(chartData).length > 0 ? (*/}
        {this.props.results.sociographic ? (
          <div className={classes.classFlex}>
            {this.props.isSelectedChart && (
              <SelectedChart
                onChartClose={this.onChartClose}
                isSidebarToggled={this.props.isSidebarToggled}
                params={this.props.selectedChartParams}
              />
            )}
            {/*gender chart data starts*/}
            <GridItem xs={12} sm={12} md={12}>
              <CustomSelect
                labelText="Male/Female"
                id="maleFemale"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => {
                    this.setState({
                      gender: e.target.value
                    });
                  },
                  value: gender !== "" ? gender : "all"
                }}
              >
                {SociographicService.gendnerItems.map(item => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </CustomSelect>
              <Chart
                type={"Bar"}
                color={"success"}
                data={genderChartData}
                title={gender.toUpperCase()}
                onChartClick={() =>
                  this.onChartClick(
                    "Bar",
                    "success",
                    genderChartData,
                    gender.toUpperCase()
                  )
                }
              />
            </GridItem>
            {/*gender chart ends*/}
            {/*households by age chart data starts*/}
            <GridItem xs={12} sm={12} md={4}>
              <Chart
                type={"Bar"}
                color={"success"}
                data={houseHoldsByAgeChartData}
                title={"Households age of households"}
                onChartClick={() =>
                  this.onChartClick(
                    "Bar",
                    "success",
                    houseHoldsByAgeChartData,
                    "Households age of households"
                  )
                }
              />
            </GridItem>
            {/*households by age chart data ends*/}
            {/*households monthly income chart data starts*/}
            <GridItem xs={12} sm={12} md={4}>
              <Chart
                type={"Bar"}
                color={"success"}
                data={withIncomeChartData}
                title={"Households with a monthly net income"}
                onChartClick={() =>
                  this.onChartClick(
                    "Bar",
                    "success",
                    withIncomeChartData,
                    "Households with a monthly net income"
                  )
                }
              />
            </GridItem>
            {/*households monthly income chart data ends*/}
            {/*maintaince chart data starts*/}
            <GridItem xs={12} sm={12} md={4}>
              <Chart
                type={"Bar"}
                color={"success"}
                data={maintenceChartData}
                title={"The general maintenance status"}
                onChartClick={() =>
                  this.onChartClick(
                    "Bar",
                    "success",
                    maintenceChartData,
                    "The general maintenance status"
                  )
                }
              />
            </GridItem>
            {/*maintaince chart data ends*/}
          </div>
        ) : (
          <div className={classes.socLoader}>
            <Loader />
          </div>
        )}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ position: "relative" }}
        >
          {this.props.loaders.nearByInfo ? <Loader /> : ""}
          {this.props.searchFormParams.address !== "" &&
          this.props.results.totalResults !== "" &&
          this.props.results.city !== "" ? (
            <NewsAndStatista city={this.props.results.city} />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  const { search, results, ui } = state;
  return {
    searchFormParams: {
      address: search.address
    },
    searchParams: {
      categories: search.categories
    },
    results: {
      city: results.city,
      totalResults: results.totalResults,
      sociographic: results.sociographic
    },
    loaders: {
      socLoading: ui.socLoading,
      nearByInfo: ui.nearByInfo
    },
    isSelectedChart: ui.isSelectedChart,
    isSidebarToggled: ui.isSidebarToggled,
    selectedChartParams: results.selectedChartParams,
    randomText: results.randomText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModals: value => dispatch(actionCreator.toggleModals(value)),
    setParams: (property, value) => dispatch(actionCreator.set(property, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Sociographic));
