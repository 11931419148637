import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { MenuItem } from "@material-ui/core";

import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CustomSelect from "../CustomSelect/CustomSelect";
import CustomInput from "../CustomInput/CustomInput";
import CustomButton from "../CustomButtons/Button";
import Suggestions from "../Search/Suggestions";

import ProductAPI from "../../api/Product";
import * as ZipcodeAPI from "../../api/Zipcode";
import * as NotificationAPI from "../../api/Notification";

import * as actionCreator from "../../store/actions";

const AddForm = props => {
  const { set, notifications, selectedNotification } = props;

  const [loading, setLoading] = useState(false);
  const [suggestionDropdown, setSuggestionDropdown] = useState(false);

  const [categories, setCategories] = useState([]);
  const [id, setId] = useState(null);
  const [type, setType] = useState("all");
  const [category, setCategory] = useState("");
  const [minRooms, setMinRooms] = useState(1);
  const [maxRooms, setMaxRooms] = useState(2);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minCoveredArea, setMinCoveredArea] = useState(0);
  const [maxCoveredArea, setMaxCoveredArea] = useState(0);
  const [variation, setVariation] = useState(0);
  const [mietrenditeVariation, setMietrenditeVariation] = useState(0);
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const searchAddressHandler = event => {
    setAddress(event.target.value);
    setLoading(true);

    ZipcodeAPI.search(address)
      .then(resp => resp.data.data)
      .then(data => {
        setLoading(false);
        setSuggestions(data.map(item => ({ description: item.zipcode_place })));
        setSuggestionDropdown(true);
      });
  };

  const submitHandler = () => {
    if (id) {
      NotificationAPI.update(
        id,
        address,
        type,
        category,
        minPrice,
        maxPrice,
        minCoveredArea,
        maxCoveredArea,
        minRooms,
        maxRooms,
        variation,
        mietrenditeVariation
      )
        .then(resp => resp.data.data)
        .then(() => window.location.reload());
    } else {
      NotificationAPI.store(
        address,
        type,
        category,
        minPrice,
        maxPrice,
        minCoveredArea,
        maxCoveredArea,
        minRooms,
        maxRooms,
        variation,
        mietrenditeVariation
      )
        .then(resp => resp.data)
        .then(data => {
          props.toggleModal({ openModal: false });
          const alert = data.data;
          const notfs = [...notifications];
          notfs.push([
            {
              label: alert.alert_zip
            },
            {
              label: alert.alert_kind
            },
            {
              label: alert.alert_kind
            },
            {
              label: `${alert.alert_min_price} - ${alert.alert_max_price} €`
            },
            {
              label: `${alert.alert_min_area} - ${alert.alert_max_area} m²`
            },
            {
              label: `${alert.alert_min_rooms} - ${alert.alert_max_rooms} Rooms`
            },
            {
              label: `${alert.alert_min_percentage} %`
            },
            {
              label: " "
            }
          ]);
          set({
            notifications: notfs
          });
        });
    }
  };

  const rooms = [];

  for (let i = 1; i <= 10; i++) {
    rooms.push(i);
  }

  useEffect(() => {
    ProductAPI.getTypes()
      .then(resp => resp.data.data)
      .then(data => {
        if (selectedNotification) {
          setId(selectedNotification.alert_id);
          setType(selectedNotification.alert_kind);
          setAddress(selectedNotification.alert_zip);
          setMinPrice(selectedNotification.alert_min_price);
          setMaxPrice(selectedNotification.alert_max_price);
          setMinCoveredArea(selectedNotification.alert_min_area);
          setMaxCoveredArea(selectedNotification.alert_max_area);
          setMinRooms(selectedNotification.alert_min_rooms);
          setMaxRooms(selectedNotification.alert_max_rooms);
          setVariation(selectedNotification.alert_min_percentage);
          setMietrenditeVariation(selectedNotification.alert_mietrendite);
        }

        return setCategories(
          data.map(item => ({
            id: item.type_id,
            name: item.type_name,
            property: item.type_property
          }))
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredCategories =
    type === "all" ? [] : categories.filter(cat => cat.property === type);

  return (
    <GridContainer>
      <GridItem xs={12} md={6}>
        <CustomSelect
          labelText={"Type"}
          id={"type"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => setType(event.target.value),
            value: type
          }}
        >
          <MenuItem key={"0"} value={"all"}>
            All
          </MenuItem>
          <MenuItem key={"1"} value={"Mieten"}>
            Mieten
          </MenuItem>
          <MenuItem key={"2"} value={"Kaufen"}>
            Kaufen
          </MenuItem>
        </CustomSelect>
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomSelect
          labelText={"Categories"}
          id={"category"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => setCategory(event.target.value),
            value: category
          }}
        >
          <MenuItem key={"0"} value={"all"}>
            All
          </MenuItem>
          {categories.length > 0 &&
            filteredCategories.map((cat, index) => {
              return (
                <MenuItem key={`category-${index}`} value={cat.name}>
                  {cat.name}
                </MenuItem>
              );
            })}
        </CustomSelect>
      </GridItem>
      <GridItem xs={12} md={2} style={{ position: "relative" }}>
        <CustomInput
          labelText={"Address"}
          id={"address"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: searchAddressHandler,
            value: address
          }}
        />
        {suggestionDropdown && suggestions.length > 0 ? (
          <Suggestions
            getSuggestionItemProps={name => {
              return {
                onClick: e => {
                  setAddress(e.target.innerHTML);
                  setSuggestionDropdown(false);
                }
              };
            }}
            loading={loading}
            suggestions={suggestions}
          />
        ) : (
          ""
        )}
      </GridItem>
      <GridItem xs={12} md={2}>
        <CustomSelect
          labelText={"Min. Rooms"}
          id={"min-rooms"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => setMinRooms(event.target.value),
            value: minRooms
          }}
        >
          {rooms.map(room => (
            <MenuItem key={`room-${room}`} value={room}>
              {room}
            </MenuItem>
          ))}
        </CustomSelect>
      </GridItem>
      <GridItem xs={12} md={2}>
        <CustomSelect
          labelText={"Max. Rooms"}
          id={"max-rooms"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => setMaxRooms(event.target.value),
            value: maxRooms
          }}
        >
          {rooms.map(room => (
            <MenuItem key={`room-${room}`} value={room}>
              {room}
            </MenuItem>
          ))}
        </CustomSelect>
      </GridItem>
      <GridItem xs={12} md={3}>
        <CustomInput
          labelText={"Min. Price"}
          id={"min-price"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => setMinPrice(event.target.value),
            value: minPrice
          }}
        />
      </GridItem>
      <GridItem xs={12} md={3}>
        <CustomInput
          labelText={"Max. Price"}
          id={"max-price"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => setMaxPrice(event.target.value),
            value: maxPrice
          }}
        />
      </GridItem>
      <GridItem xs={12} md={3}>
        <CustomInput
          labelText={"Min. Covered area (m²)"}
          id={"min-codered-area"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => setMinCoveredArea(event.target.value),
            value: minCoveredArea
          }}
        />
      </GridItem>
      <GridItem xs={12} md={3}>
        <CustomInput
          labelText={"Max. Covered area (m²)"}
          id={"max-codered-area"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => setMaxCoveredArea(event.target.value),
            value: maxCoveredArea
          }}
        />
      </GridItem>
      <GridItem xs={12} md={3}>
        <CustomInput
          labelText={"Variation (%)"}
          id={"variation"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => setVariation(event.target.value),
            value: variation
          }}
        />
      </GridItem>
      <GridItem xs={12} md={3}>
        <CustomInput
          labelText={"Mietrendite Variation (%)"}
          id={"mietrendite-variation"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => setMietrenditeVariation(event.target.value),
            value: mietrenditeVariation
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <CustomButton color={"primary"} round onClick={submitHandler}>
          Submit
        </CustomButton>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    notifications: state.notification.notifications,
    selectedNotification: state.notification.selectedNotification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    set: value => dispatch(actionCreator.setNotification(value)),
    toggleModal: value => dispatch(actionCreator.toggleMultiple(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddForm);
