import { combineReducers } from "redux";

// reducers
import authReducer from "./reducers/auth";
import dashboardReducer from "./reducers/dashboard";
import uiReducer from "./reducers/ui";
import searchReducer from "./reducers/search";
import resultsReducer from "./reducers/results";
import varianceReducer from "./reducers/variance";
import notificationReducer from "./reducers/notification";
import marketAnalyseReducer from "./reducers/market-analyse/market-analyse.reducer";
import featuresSearch from "./reducers/features-search.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  ui: uiReducer,
  search: searchReducer,
  results: resultsReducer,
  variance: varianceReducer,
  notification: notificationReducer,
  marketAnalyse: marketAnalyseReducer,
  featuresSearch: featuresSearch
});

export default rootReducer;
