import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Loader from "../../ui/Loader/Loader";
import FeaturesMap from "./Map";
import CheckBoxesList from "./CheckBoxesList";
import FeaturesCards from "./FeaturesCards";
import styles from "../../assets/jss/material-dashboard-react/components/feature-search/features";
import Snackbar from "../Snackbar/Snackbar";
const useStyles = makeStyles(styles);

const ResultsContainer = props => {
  const {
    loaders,
    results,
    responseError,
    featuresSearch,
    checkBoxes,
    handleAllChecked,
    handleCheckboxes
  } = props;
  const [isOpen, toggleIsOpen] = useState(true);
  const classes = useStyles();

  const onSnackbarClose = () => {
    toggleIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className={classes.splitResultsContainer}>
        {featuresSearch.map((p, i) => {
          return (
            <GridItem xs={12} sm={12} md={12} lg={12} key={i}>
              {responseError && responseError[i] && (
                <Snackbar
                  place="bc"
                  color="danger"
                  message={responseError[i]}
                  open={isOpen}
                  closeNotification={() => onSnackbarClose(i)}
                  close
                />
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {loaders[i].featuresLoading === true && (
                    <div className={classes.searchResult}>
                      <Loader />
                    </div>
                  )}
                  {loaders[i].featuresLoading === false &&
                  responseError[i] === "" ? (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8} lg={8}>
                          <FeaturesMap
                            index={i}
                            center={{
                              lat: featuresSearch[i].lat,
                              lng: featuresSearch[i].lng
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                          <CheckBoxesList
                            checkBoxes={checkBoxes}
                            handleAllChecked={handleAllChecked}
                            handleCheckboxes={handleCheckboxes}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  ) : (
                    ""
                  )}
                </GridItem>
              </GridContainer>
            </GridItem>
          );
        })}
      </div>
      {results.length > 0 &&
        ((loaders[0] && loaders[0].featuresLoading === false) ||
          (loaders[1] && loaders[1].featuresLoading === false)) && (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FeaturesCards
              color={"info"}
              checkedItems={checkBoxes}
              results={results}
              featuresSearchParams={featuresSearch}
            />
          </GridItem>
        )}
    </React.Fragment>
  );
};

export default ResultsContainer;
